import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {CheckoutState} from "../checkout/state/checkout.state";
import { CertificationApplicationStatus, FormResponse } from "../shared/graphql/generated/graphql";
import {combineLatestWith, map, Observable} from "rxjs";
import {MenuItem} from "./app.menu.items";
import {CurrentUserState} from "../state/current_user/current_user.state";
import {CertificationState} from "../certification/state/certification.state";
import { CertificationApplicationState } from "../certification/application/state/certification-application.state";

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  @Select(CheckoutState.formResponse) formResponse$!: FormResponse | null;

  menuItems$: Observable<MenuItem[]>;

  _baseModel = [
      {
        label: 'Startseite',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/']
      },
      {
        label: 'NC-Ergebnis',
        icon: 'pi pi-fw pi-chart-bar',
        routerLink: ['/form-responses']
      },
    {
      label: 'Dokumente',
      icon: 'pi pi-fw pi-file',
      routerLink: ['/documents']
    },
  ] as MenuItem[];

  constructor(
    private store: Store,
  ) {
    this.menuItems$ = this.store.select(CheckoutState.formResponse).pipe(
      combineLatestWith(this.store.select(CertificationState.certificationProcessId), this.store.select(CertificationState.certificationProcess)),
    map(values => {

      const formResponse = values[0];
      const certificationProcessId = values[1];
      const certificationProcess = values[2];

      const applicationComplete = certificationProcess && certificationProcess.currentCertificationApplication?.status === CertificationApplicationStatus.Approved;

      let menuModel = this._baseModel;

      if (formResponse) {
        menuModel = [...menuModel, ...[
          {
            label: 'Kasse',
            icon: 'pi pi-fw pi-shopping-cart',
            routerLink: ['/checkout']
          },
        ]];
      }

      if (certificationProcessId) {
        menuModel = [...menuModel, ...[
          {
            label: 'Zertifizierung',
            icon: 'pi pi-fw pi-check-circle',
            routerLink: ['/certification'],
            items: [
              {
                label: 'Antragsformular' + (applicationComplete ? '  ✓' : ''),
                routerLink: ['/certification/application']
              },
              {
                label: 'Nachweise',
                routerLink: ['/certification/verification'],
                disabled: !applicationComplete
              },
            ],
          },
        ]];
      }

      return menuModel;
    })).pipe(
      map(menuItems => {
        return [
          {
            // label: 'Dashboards',
            // icon: 'pi pi-home',
            items: menuItems
          }
        ];
      })
    );
  }

  // model: any[] = [];

  ngOnInit() {
    /*this.model = [
      {
        label: 'Dashboards',
        icon: 'pi pi-home',
        items: [
          {
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/']
          },
          {
            label: 'Fragebögen',
            icon: 'pi pi-fw pi-image',
            routerLink: ['/form-responses']
          },
          {
            label: 'Checkout',
            icon: 'pi pi-fw pi-shopping-cart',
            routerLink: ['/checkout']
          },
          {
            label: 'Zertifizierung',
            icon: 'pi pi-fw pi-image',
            routerLink: ['/certification']
          },
        ]
      }
    ];*/
  }
}
