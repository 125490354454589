import {CertificationBaseInfo, CertificationProcess} from "../../../shared/graphql/generated/graphql";

export namespace CertificationBaseInfoAction {
  export class Set {
    static readonly type = "[CertificationBaseInfo] Set";
    constructor(public certificationProcessId: string, public baseInfo: CertificationBaseInfo | null, public certificationProcess: CertificationProcess) {}
  }

  export class Save {
    static readonly type = "[CertificationBaseInfo] Save";
    constructor() {}
  }

  export class Error {
    static readonly type = "[CertificationBaseInfo] Error";
    constructor(public error: string) {}
  }

}
