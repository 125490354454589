import {FormResponse} from "../../shared/graphql/generated/graphql";

export namespace FormResponsesAction {

  export namespace List {
    export class Load {
      static readonly type = '[FormResponses] List Load';
    }

    export class Loaded {
      static readonly type = '[FormResponses] List Loaded';
      constructor(public formResponses: FormResponse[]) { }
    }

    export class LoadFailed {
      static readonly type = '[FormResponses] List Load Failed';
      constructor(public error: string) { }
    }
  }

  export namespace Detail {
    export class Load {
      static readonly type = '[FormResponses] Single Load';
      constructor(public id: string) { }
    }

    export class Loaded {
      static readonly type = '[FormResponses] Single Loaded';
      constructor(public formResponse: FormResponse) { }
    }

    export class LoadFailed {
      static readonly type = '[FormResponses] Single Load Failed';
      constructor(public error: string) { }
    }
  }
}
