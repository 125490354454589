import { Injectable } from '@angular/core';
import {
  ActivateUserInput,
  AuthActivateUserGQL,
  AuthChangePasswordGQL,
  AuthLoginGQL,
  AuthLoginMutationVariables,
  AuthRefreshTokenGQL,
  AuthResetPasswordGQL,
  AuthResetPasswordRequestGQL,
  MutationAuthChangePasswordArgs
} from "../graphql/generated/graphql";
import { map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private activateAccountGQL: AuthActivateUserGQL,
    private loginGQL: AuthLoginGQL,
    private refreshTokenGQL: AuthRefreshTokenGQL,
    private changePasswordGQL: AuthChangePasswordGQL,
    private authResetPasswordRequestGQL: AuthResetPasswordRequestGQL,
    private authResetPasswordGQL: AuthResetPasswordGQL
  ) { }

  activateAccount(data: ActivateUserInput) {
    return this.activateAccountGQL
      .mutate({ data })
      .pipe(map((result) => result.data?.authActivateUser))
  }

  login(credentials: AuthLoginMutationVariables) {
    return this.loginGQL
      .mutate(credentials)
      .pipe(
        map((res) => res.data?.authLogin)
      );
  }

  refreshTokens(refreshToken: string) {
    console.log('refreshing tokens with', refreshToken);
    const context = { headers: { Authorization: `Bearer ${refreshToken}` } };
    return this.refreshTokenGQL
      .mutate({}, { context })
      .pipe(
        map((res) => res.data?.authRefreshToken)
      );
  }

  resetPasswordRequest(email: string) {
    return this.authResetPasswordRequestGQL
      .mutate({ email })
      .pipe(map((result) => result.data?.authResetPasswordRequest))
  }

  resetPassword(token: string, newPassword: string) {
    return this.authResetPasswordGQL
      .mutate({ data : { token, newPassword }})
      .pipe(map((result) => result.data?.authResetPassword))
  }

  changePassword(changePasswordInput: MutationAuthChangePasswordArgs) {
    return this.changePasswordGQL
      .mutate(changePasswordInput)
      .pipe(map((result) => result.data?.authChangePassword))
  }
}
