import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontLayoutComponent } from '../layout/front.layout.component';
import { TypeformEmbedComponent } from '../survey/pages/typeform-embed/typeform-embed.component';
import {AppLayoutComponent} from "../layout/app.layout.component";
import {AuthComponent} from "../auth/auth.component";
import {AuthGuardService} from "../shared/guards/auth-guard.service";
import {DocumentsListPageComponent} from "../documents/documents-list-page/documents-list-page.component";

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('../home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'form-responses',
        loadChildren: () => import('../form-responses/form-responses.module').then(m => m.FormResponsesModule),
      },
      {
        path: 'checkout',
        loadChildren: () => import('../checkout/checkout.module').then(m => m.CheckoutModule),
      },
      {
        path: 'certification',
        loadChildren: () => import('../certification/certification.module').then(m => m.CertificationModule),
      },
      {
        path: 'documents',
        loadChildren: () => import('../documents/documents.module').then(m => m.DocumentsModule),
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'survey',
    component: FrontLayoutComponent,
    loadChildren: () => import('../survey/survey.module').then(m => m.SurveyModule)
  },
  {
    path: 'survey/:industry/:profession',
    component: TypeformEmbedComponent
  },
  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RootRoutingModule { }
