import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';

import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import { HttpClientModule } from '@angular/common/http';
import {CHECKOUT_STATE, STORAGE_STATE} from '../state';
import { GraphQLModule } from '../shared/graphql/graphql.module';

import { ToastModule } from "primeng/toast";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AppLayoutModule } from "../layout/app.layout.module";
import { MessageService, ConfirmationService } from 'primeng/api';
import { SurveyState } from '../state/survey/survey.state';
import {AuthState} from "../state/auth/auth.state";
import {CurrentUserState} from "../state/current_user/current_user.state";
import {StorageState} from "../state/storage/storage.state";
import {FormResponsesDetailState, FormResponsesState} from "../form-responses/state/form-responses.state";
import {CheckoutState} from "../checkout/state/checkout.state";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {NgxsFormPluginModule} from "@ngxs/form-plugin";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {CertificationState} from "../certification/state/certification.state";
import {CertificationBaseInfoState} from "../certification/base-info/state/certificationBaseInfo.state";

@NgModule({
  declarations: [
    RootComponent,
  ],
  imports: [
    BrowserModule,
    RootRoutingModule,
    NgxsModule.forRoot([SurveyState, AuthState, CurrentUserState, StorageState, FormResponsesState, FormResponsesDetailState, CheckoutState, CertificationState, CertificationBaseInfoState], { developmentMode: !environment.production }),
    NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({ key: [STORAGE_STATE, CHECKOUT_STATE] }),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    GraphQLModule,
    HttpClientModule,
    ToastModule,
    ConfirmDialogModule,
    AppLayoutModule,
    environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [MessageService, ConfirmationService, { provide: LOCALE_ID, useValue: 'de-DE' }, {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },],
  bootstrap: [RootComponent]
})
export class RootModule {

  constructor() {
    registerLocaleData(localeDe, 'de', localeDeExtra);
  }

}
