import { Injectable } from '@angular/core';
import {map, Observable} from 'rxjs';
import {
  AuditRequestCreateGQL,
  CreateProfessionAuditRequestInput, Profession,
  ProfessionAuditRequest,
  ProfessionsGQL,
} from '../graphql/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(
    private auditRequestGQL: AuditRequestCreateGQL,
    private professionsGQL: ProfessionsGQL
  ) { }

  _cachedProfessions: Map<string, Profession[]> | null = null;

  requestAudit(professionAuditRequestCreateInput: CreateProfessionAuditRequestInput) {
    return this.auditRequestGQL.mutate({ professionAuditRequestCreateInput }).pipe(
      map((result) => result.data?.auditRequestCreate as ProfessionAuditRequest)
    )
  }

  getProfessionsGrouped(): Observable<Map<string, Profession[]>> {
    if (this._cachedProfessions) {
      return new Observable<Map<string, Profession[]>>((subscriber) => {
        subscriber.next(this._cachedProfessions!);
        subscriber.complete();
      });
    }

    return this.professionsGQL.watch().valueChanges.pipe(
      map((result) => {
        const professions = result.data?.professions as Profession[];
        const professionsGrouped = new Map<string, Profession[]>();
        professions.forEach((profession) => {
          const category = profession.category;
          if (!professionsGrouped.has(category.name)) {
            professionsGrouped.set(category.name, []);
          }
          professionsGrouped.get(category.name)?.push(profession);
        });
        return professionsGrouped;
      }));
  }
}
