import { Injectable } from '@angular/core';
import {map, Observable} from 'rxjs';
import {
  AnswerVerification,
  AnswerVerificationCreateOrUpdateGQL,
  AuditRequestCreateGQL, CertificationBaseInfo, CertificationBaseInfoCreateOrUpdateGQL,
  CertificationProcess,
  CertificationProcessGQL, CreateAnswerVerificationInput,
  CreateCertificationBaseInfoInput,
  CreatePaymentInput,
  CreateProfessionAuditRequestInput, DocumentDeleteGQL,
  InitializedPayment,
  PaymentInitializeGQL,
  ProfessionAuditRequest, VerificationRequest, VerificationRequestCreateGQL,
} from '../graphql/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class CertificationService {
  constructor(
    private certificationProcessGQL: CertificationProcessGQL,
    private certificationBaseInfoCreateOrUpdateGQL: CertificationBaseInfoCreateOrUpdateGQL,
    private answerVerificationCreateOrUpdateGQL: AnswerVerificationCreateOrUpdateGQL,
    private documentDeleteGQL: DocumentDeleteGQL,
    private verificationRequestCreateGQL: VerificationRequestCreateGQL,
  ) { }

  getActiveCertificationProcess(): Observable<CertificationProcess> {
    return this.certificationProcessGQL.watch().valueChanges.pipe(
      map((result) => {
        return result.data.certificationProcess as CertificationProcess;
      }));
  }

  createOrUpdateBaseInfo(data: CreateCertificationBaseInfoInput): Observable<CertificationBaseInfo> {
    return this.certificationBaseInfoCreateOrUpdateGQL.mutate(
      {
        data
      }
    ).pipe(
      map((result) => {
        return result.data!.certificationBaseInfoCreateOrUpdate as CertificationBaseInfo;
      }));
  }

  answerVerificationCreateOrUpdate(data: CreateAnswerVerificationInput): Observable<AnswerVerification> {
    return this.answerVerificationCreateOrUpdateGQL.mutate(
      {
        data
      }
    ).pipe(
      map((result) => {
        return result.data!.answerVerificationCreateOrUpdate as AnswerVerification;
      }));
  }

  verificationRequestCreate(certificationProcessId: string): Observable<VerificationRequest> {
    return this.verificationRequestCreateGQL.mutate(
      {
        certificationProcessId
      }
    ).pipe(
      map((result) => {
        return result.data!.verificationRequestCreate as VerificationRequest;
      }));
  }

  documentDelete(id: string): Observable<boolean> {
    return this.documentDeleteGQL.mutate(
      {
        id
      }
    ).pipe(
      map((result) => {
        return result.data!.documentDelete as boolean;
      }));
  }
}
