import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { createWidget } from "@typeform/embed";
import { combineLatest } from 'rxjs';
import { SurveyState } from 'src/app/state/survey/survey.state';

@Component({
  selector: 'app-typeform-embed',
  templateUrl: './typeform-embed.component.html',
  styleUrls: ['./typeform-embed.component.scss']
})
export class TypeformEmbedComponent implements OnInit {

  constructor(private route: ActivatedRoute, private store: Store) { }

  ngOnInit(): void {
    combineLatest([
      this.route.paramMap,
      this.store.select(SurveyState.formByIndustry)
    ]).subscribe(([params, formByIndustry]) => {
      const industry = params.get('industry')!;
      const profession = params.get('profession')!;

      const formId = formByIndustry(industry);

      createWidget(formId, {
        container: document.querySelector("#form")!,
        hidden: { profession }
      });
    })
  }
}
