import { Injectable } from '@angular/core';
import {map, Observable} from 'rxjs';
import {
  AnswerVerification,
  AnswerVerificationCreateOrUpdateGQL,
  AuditRequestCreateGQL, CertificationAgreementDeleteGQL,
  CertificationApplication,
  CertificationApplicationEditGQL, CertificationApplicationSubmitGQL,
  CertificationBaseInfo,
  CertificationBaseInfoCreateOrUpdateGQL,
  CertificationProcess,
  CertificationProcessGQL,
  CreateAnswerVerificationInput,
  CreateCertificationBaseInfoInput,
  CreatePaymentInput,
  CreateProfessionAuditRequestInput,
  DocumentDeleteGQL,
  InitializedPayment,
  MutationCertificationApplicationEditArgs, MutationCertificationApplicationSubmitArgs,
  PaymentInitializeGQL,
  PowerOfAttorney,
  ProfessionAuditRequest,
  VerificationRequest,
  VerificationRequestCreateGQL,
} from '../graphql/generated/graphql';
import { result } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CertificationService {
  constructor(
    private certificationProcessGQL: CertificationProcessGQL,
    private certificationBaseInfoCreateOrUpdateGQL: CertificationBaseInfoCreateOrUpdateGQL,
    private certificationApplicationEditGQL: CertificationApplicationEditGQL,
    private certificationApplicationSubmitGQL: CertificationApplicationSubmitGQL,
    private answerVerificationCreateOrUpdateGQL: AnswerVerificationCreateOrUpdateGQL,
    private documentDeleteGQL: DocumentDeleteGQL,
    private verificationRequestCreateGQL: VerificationRequestCreateGQL,
    private certificationAgreementDeleteGQL: CertificationAgreementDeleteGQL,
  ) { }

  getActiveCertificationProcess(): Observable<CertificationProcess> {
    return this.certificationProcessGQL.fetch().pipe(
      map((result) => {
        return result.data.certificationProcess as CertificationProcess;
      }));
  }

  createOrUpdateBaseInfo(data: CreateCertificationBaseInfoInput): Observable<CertificationBaseInfo> {
    return this.certificationBaseInfoCreateOrUpdateGQL.mutate(
      {
        data
      }
    ).pipe(
      map((result) => {
        return result.data!.certificationBaseInfoCreateOrUpdate as CertificationBaseInfo;
      }));
  }

  answerVerificationCreateOrUpdate(data: CreateAnswerVerificationInput): Observable<AnswerVerification> {
    return this.answerVerificationCreateOrUpdateGQL.mutate(
      {
        data
      }
    ).pipe(
      map((result) => {
        return result.data!.answerVerificationCreateOrUpdate as AnswerVerification;
      }));
  }

  verificationRequestCreate(certificationProcessId: string): Observable<VerificationRequest> {
    return this.verificationRequestCreateGQL.mutate(
      {
        certificationProcessId
      }
    ).pipe(
      map((result) => {
        return result.data!.verificationRequestCreate as VerificationRequest;
      }));
  }

  documentDelete(id: string): Observable<boolean> {
    return this.documentDeleteGQL.mutate(
      {
        id
      }
    ).pipe(
      map((result) => {
        return result.data!.documentDelete as boolean;
      }));
  }

  certificationApplicationEdit({ certificationProcessId, data }: MutationCertificationApplicationEditArgs): Observable<CertificationApplication> {
    return this.certificationApplicationEditGQL.mutate(
      {
        certificationProcessId,
        data
      }
    ).pipe(
      map((result) => {
        return result.data!.certificationApplicationEdit as CertificationApplication;
      }));
  }

  certificationApplicationSubmit(certificationProcessId: string): Observable<CertificationApplication> {
    return this.certificationApplicationSubmitGQL.mutate(
      {
        certificationProcessId,
      }
    ).pipe(
      map((result) => {
        return result.data!.certificationApplicationSubmit as CertificationApplication;
      }));
  }

  certificationAgreementDelete(certificationProcessId: string): Observable<boolean> {
    return this.certificationAgreementDeleteGQL.mutate(
      {
        certificationProcessId,
      }).pipe(
      map((result) => {
        return result.data!.certificationAgreementDelete as boolean;
      }));
  }
}
