import { Injectable } from '@angular/core';
import {map, Observable} from 'rxjs';
import {
  AuditRequestCreateGQL, CreatePaymentInput,
  CreateProfessionAuditRequestInput, InitializedPayment,
  PaymentInitializeGQL,
  ProfessionAuditRequest,
} from '../graphql/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  constructor(
    private paymentInitializeGQL: PaymentInitializeGQL,
  ) { }

  initializePayment(createPaymentInput: CreatePaymentInput): Observable<InitializedPayment> {
    return this.paymentInitializeGQL.mutate({ data: createPaymentInput }).pipe(
      map((result) => result.data?.paymentInitialize as InitializedPayment)
    )
  }
}
