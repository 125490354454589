import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';

import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import { HttpClientModule } from '@angular/common/http';
import {CHECKOUT_STATE, STORAGE_STATE} from '../state';
import { GraphQLModule } from '../shared/graphql/graphql.module';

import { ToastModule } from "primeng/toast";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AppLayoutModule } from "../layout/app.layout.module";
import { MessageService, ConfirmationService } from 'primeng/api';
import { SurveyState } from '../state/survey/survey.state';
import {AuthState} from "../state/auth/auth.state";
import {CurrentUserState} from "../state/current_user/current_user.state";
import {StorageState} from "../state/storage/storage.state";
import {FormResponsesDetailState, FormResponsesState} from "../form-responses/state/form-responses.state";
import {CheckoutState} from "../checkout/state/checkout.state";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {NgxsFormPluginModule} from "@ngxs/form-plugin";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {CertificationState} from "../certification/state/certification.state";
import { CertificationApplicationState } from "../certification/application/state/certification-application.state";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

Sentry.init({
  dsn: "https://c0d60f28315691e54e5904d8af684b93@o4508275264454656.ingest.de.sentry.io/4508330993057872",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', 'https://api.nachhaltigkeitsaudit.org/graphql', 'https://api.stage.nachhaltigkeitsaudit.org/graphql'],
  environment: environment.production ? 'production' : 'development',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

@NgModule({
  declarations: [
    RootComponent,
  ],
  imports: [
    BrowserModule,
    RootRoutingModule,
    NgxsModule.forRoot([SurveyState, AuthState, CurrentUserState, StorageState, FormResponsesState, FormResponsesDetailState, CheckoutState, CertificationState, CertificationApplicationState], { developmentMode: !environment.production }),
    NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({ key: [STORAGE_STATE, CHECKOUT_STATE] }),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    GraphQLModule,
    HttpClientModule,
    ToastModule,
    ConfirmDialogModule,
    AppLayoutModule,
    environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [RootComponent]
})
export class RootModule {

  constructor() {
    registerLocaleData(localeDe, 'de', localeDeExtra);
  }

}
