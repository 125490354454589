import {
  AnswerVerification,
  AnswerVerificationStatus,
  FormQuestion,
  FormResponseAnswer
} from "../../shared/graphql/generated/graphql";
import {FormQuestionWithAnswersStatus} from "./certification.state";

export function getLastAnswerVerification(answer: FormResponseAnswer): AnswerVerification | null {
  if (answer.answerVerifications == null) {
    return null;
  }
  return answer.answerVerifications[answer.answerVerifications.length - 1];
}

export function  getQuestionStatus(question: FormQuestion, answers: FormResponseAnswer[]): FormQuestionWithAnswersStatus {
  const answerStatus = answers.map(answer => getLastAnswerVerification(answer)?.status ?? AnswerVerificationStatus.Incomplete);
  if (answerStatus.includes(AnswerVerificationStatus.Incomplete)) {
    return FormQuestionWithAnswersStatus.Incomplete;
  } else if (answerStatus.includes(AnswerVerificationStatus.Declined)) {
    return FormQuestionWithAnswersStatus.Rejected;
  } else if (answerStatus.every(status => status === AnswerVerificationStatus.Verified)) {
    return FormQuestionWithAnswersStatus.Verified;
  } else if (answerStatus.every(status => status === AnswerVerificationStatus.Submitted)) {
    return FormQuestionWithAnswersStatus.Submitted;
  } else if (answerStatus.every(status => status === AnswerVerificationStatus.Complete || status === AnswerVerificationStatus.Verified)) {
    return FormQuestionWithAnswersStatus.Complete;
  } else {
    return FormQuestionWithAnswersStatus.Unknown;
  }
}
