
export namespace Storage {
  export class SetSelectedProductId {
    static readonly type = '[Storage] SetSelectedProductId';

    constructor(public productId: number) {
    }
  }

  export class SetAccessToken {
    static readonly type = '[Storage] SetAccessToken';

    constructor(public accessToken: string) {
    }
  }

  export class SetRefreshToken {
    static readonly type = '[Storage] SetRefreshToken';

    constructor(public refreshToken: string) {
    }
  }

  export class ClearAccessRefreshToken {
    static readonly type = '[Storage] ClearAccessRefreshToken';
  }

  export class RememberMe {
    static readonly type = "[Storage] RememberMe"
    constructor(public value: boolean) { }
  }
}
