import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {FormResponse, FormResponseGQL, FormResponseListOwnGQL} from "../graphql/generated/graphql";

@Injectable({
  providedIn: 'root',
})
export class FormResponseService {
  constructor(
    private formResponseListOwnGQL: FormResponseListOwnGQL,
    private formResponseGQL: FormResponseGQL,
  ) { }

  getFormResponses() {
    return this.formResponseListOwnGQL.watch().valueChanges.pipe(
      map((result) => result.data.formResponseListOwn as FormResponse[])
    )
  }

  getFormResponseById(id: string) {
    return this.formResponseGQL.watch({ formResponseId: id }).valueChanges.pipe(
      map((result) => result.data.formResponse as FormResponse)
    )
  }
}
