export const environment = {
  environmentName: 'Staging',

  production: false,

  graphQLEndpoint: "/graphql",
  subscriptionEndpoint: "ws://api.stage.nachhaltigkeitsaudit.org/graphql",
  serverUrl: "https://api.stage.nachhaltigkeitsaudit.org",

  stripePublicKey: "pk_test_51JRezLIfEP23dB84owF6MILTk4sKhfTvqSIb7jRyXDX0O18k0YJ089LZmhnAI5shF6nxnq29UAIRWkJlnH0seGnl00CToHHY2n"
};
