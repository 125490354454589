import {ActivateUserInput, FormResponse} from "../../shared/graphql/generated/graphql";

export namespace CheckoutAction {

  export class Initialize {
    static readonly type = "[Checkout] Initialize";
    constructor(public formResponse: FormResponse) {}
  }

  export class Checkout {
    static readonly type = "[Checkout] Checkout";
  }

  export class Error {
    static readonly type = "[Checkout] Error";
    constructor(public error: string) {}
  }

  export class Clear {
    static readonly type = "[Checkout] Clear";
  }

}
