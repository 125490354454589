import {Injectable, NgZone} from "@angular/core";
import {Action, Selector, State, StateContext, Store} from "@ngxs/store";
import {MessageService} from "primeng/api";
import {tap} from "rxjs";
import {CheckoutStateModel} from "./checkout.model";
import {FormResponseService} from "../../shared/services/form-response.service";
import {CHECKOUT_STATE} from "../../state";
import {CheckoutAction} from "./checkout.action";
import {CurrentUserState} from "../../state/current_user/current_user.state";
import {UpdateFormValue} from "@ngxs/form-plugin";
import {CheckoutService} from "../../shared/services/checkout.service";
import {CompanyType, CreatePaymentInput} from "../../shared/graphql/generated/graphql";
import {getErrorMessageForCode} from "../../shared/utils/error_message_mapping";
import { environment } from "../../../environments/environment";

// Type definition for Stripe (from @types/stripe-v3)
declare var Stripe: any;

@State<CheckoutStateModel>({
  name: CHECKOUT_STATE,
  defaults: {
    formResponse: null,
    price: 0,
    companyType: CompanyType.Gastronomy,
    loading: false,
    error: null,
    checkoutForm: {
      model: {
        companyName: '',
        employeeCount: 1,
        billingAddress: {
          street: '',
          houseNumber: '',
          zipCode: '',
          city: '',
          state: '',
          country: 'Deutschland'
        },
        certificationAgreement: false,
      },
      dirty: false,
      status: '',
      errors: {}
  }}
})
@Injectable()
export class CheckoutState {

  stripe: any;

  constructor(
    private messageService: MessageService,
    private formResponseService: FormResponseService,
    private store: Store,
    private checkoutService: CheckoutService,
    private zone: NgZone
  ) {
    this.zone.run(() => {
      this.stripe = Stripe(environment.stripePublicKey);
    });
  }


  @Selector()
  static formResponse(state: CheckoutStateModel) {
    return state.formResponse;
  }

  @Selector()
  static checkoutForm(state: CheckoutStateModel) {
    return state.checkoutForm;
  }

  @Selector()
  static loading(state: CheckoutStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: CheckoutStateModel) {
    return state.error;
  }

  @Selector()
  static priceTotal(state: CheckoutStateModel) {
    return state.price;
  }

  @Selector()
  static isSoloSelfEmployed(state: CheckoutStateModel) {
    return state.companyType === CompanyType.SoloSelfEmployed;
  }

  @Selector()
  static companyTypeString(state: CheckoutStateModel): string {
    switch (state.companyType) {
      case CompanyType.Gastronomy:
        return 'Gastronomie';
      case CompanyType.SoloSelfEmployed:
        return 'Selbstständig';
      case CompanyType.Kmu:
        return 'KMU';
      case null:
        return '<NULL>';
      case undefined:
        return '<UNDEFINED>';
    }
  }

  @Action(CheckoutAction.Initialize)
  initialize(ctx: StateContext<CheckoutStateModel>, { formResponse }: CheckoutAction.Initialize) {

    const fullName =  this.store.selectSnapshot(CurrentUserState.fullName);
    const companyName = this.store.selectSnapshot(CurrentUserState.companyName);

    const companyType = formResponse.form.companyType;
    const userCompanyName = companyType != CompanyType.SoloSelfEmployed || companyName ? companyName : fullName;

    console.log('userCompanyName', userCompanyName);

    ctx.patchState({
      formResponse,
      loading: false,
      error: null,
      companyType: formResponse.form.companyType as CompanyType
    });

    if (companyType == CompanyType.SoloSelfEmployed) {
      ctx.dispatch(
        new UpdateFormValue({
          path: `${CHECKOUT_STATE}.checkoutForm`,
          value: 1,
          propertyPath: 'employeeCount'
        })
      )
    }

    ctx.dispatch(
      new UpdateFormValue({
        path: `${CHECKOUT_STATE}.checkoutForm`,
        value: userCompanyName,
        propertyPath: 'companyName'
      })
    )

    ctx.dispatch(
      new UpdateFormValue({
        path: `${CHECKOUT_STATE}.checkoutForm`,
        value: false,
        propertyPath: 'certificationAgreement'
      })
    )
  }

  _getPrice(companyType: CompanyType, employeeCount: number): number {
    if (companyType === CompanyType.SoloSelfEmployed) {
      return 490;
    }

    if (employeeCount < 10) {
      return 690;
    }

    if (employeeCount < 30) {
      return 890;
    }

    if (employeeCount < 50) {
      return 1290;
    }

    return 1590;
  }

  @Action(UpdateFormValue, { cancelUncompleted: true })
  updateFormValue(ctx: StateContext<CheckoutStateModel>, { payload }: UpdateFormValue) {
    if (payload.path != 'checkout.checkoutForm') {
      return;
    }
    ctx.patchState({
      price: this._getPrice(ctx.getState().companyType!, payload.value.employeeCount)
    })
  }

  @Action(CheckoutAction.Checkout)
  checkout(ctx: StateContext<CheckoutStateModel>) {

    ctx.patchState({
      loading: true,
      error: null,
    });

    const checkoutForm = ctx.getState().checkoutForm.model;
    const billingDetails = ctx.getState().checkoutForm.model.billingAddress;

    const createPaymentInput: CreatePaymentInput = {
      billingAddressStreet: billingDetails.street,
      billingAddressHouseNumber: billingDetails.houseNumber,
      billingAddressZip: billingDetails.zipCode,
      billingAddressCity: billingDetails.city,
      billingAddressState: billingDetails.state,
      billingAddressCountry: billingDetails.country,
      companyName: checkoutForm.companyName,
      employeeAmount: checkoutForm.employeeCount,
      taxNumber: checkoutForm.taxNumber,
      formResponseId: ctx.getState().formResponse!.id,
    }

    return this.checkoutService.initializePayment(createPaymentInput).pipe(
      tap({
        next: (formResponse) => {
          ctx.patchState({
            loading: false
          });
          // ctx.dispatch(new FormResponsesAction.Detail.Loaded(formResponse))
          // window.location.href = `https://checkout.stripe.com/pay/c/${formResponse.stripeCheckoutSessionId}`
          this.stripe.redirectToCheckout({
            sessionId: formResponse.stripeCheckoutSessionId
          }).then((result: any) => {
            ctx.patchState({
              loading: false,
            });
            if (result.error) {
              ctx.dispatch(new CheckoutAction.Error(getErrorMessageForCode(result.error)))
            }
          });
        },
        error: (err) => {
          ctx.dispatch(new CheckoutAction.Error(getErrorMessageForCode(err)))
        }
      }));
  }

  @Action(CheckoutAction.Error)
  error(ctx: StateContext<CheckoutStateModel>, { error }: CheckoutAction.Error) {
    ctx.patchState({
      loading: false,
      error,
    });
  }

  @Action(CheckoutAction.Clear)
  clear(ctx: StateContext<CheckoutStateModel>) {
    ctx.patchState({
      formResponse: null,
      loading: false,
      error: null,
    });
  }
}
