import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, concat, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from "@apollo/client/link/context";
import { environment } from "../../../environments/environment";
import { onError } from "@apollo/client/link/error";
import { Store } from "@ngxs/store";
import { AuthState } from "../../state/auth/auth.state";
import { StorageState } from 'src/app/state/storage/storage.state';
import { GraphQLError } from 'graphql/error';
import { capitalize } from 'lodash';

// const uri = 'https://api.stage-v3.standortmonitor.net/graphql'; // <-- add the URL of the GraphQL server here

export function createApollo(httpLink: HttpLink, store: Store): ApolloClientOptions<any> {
  /*const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));*/

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const formatErrorLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      // Flatten the error to give detailed error message
      // Note: for admin only
      if (response.errors && store.selectSnapshot(AuthState.isUserAdmin)) {
        const flatError = ((response.errors[0] as GraphQLError).extensions['response'] as { message: string[] }).message[0];
        response.errors[0].message = capitalize(flatError)
      }
      return response;
    });
  });

  const auth = setContext((operation, context) => {

    const token = store.selectSnapshot(AuthState.accessToken) ?? store.selectSnapshot(StorageState.accessToken);

    if ('headers' in context && 'Authorization' in context['headers']) return context;
    if (!token) return context;

    return { ...context, headers: { ...context['headers'], Authorization: `Bearer ${token}` } };
  });

  const link = ApolloLink.from([formatErrorLink, errorLink, auth, httpLink.create({ uri: environment.serverUrl + environment.graphQLEndpoint })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Store],
    },
  ],
})

export class GraphQLModule {

}
