import {Injectable} from "@angular/core";
import {Action, createSelector, Selector, State, StateContext} from "@ngxs/store";
import {MessageService} from "primeng/api";
import {tap} from "rxjs";
import {FormResponseDetailStateModel, FormResponsesStateModel} from "./form-responses.model";
import {FormResponsesAction} from "./form-responses.action";
import {FormResponse, FormResponseListOwnGQL} from "../../shared/graphql/generated/graphql";
import {FormResponseService} from "../../shared/services/form-response.service";
import {FORM_RESPONSE_DETAIL_STATE, FORM_RESPONSES_STATE} from "../../state";
import {FormResponseScoreTopicDetailedEvaluation} from "../../state/interfaces/form_response_detailed_evaluation";
import {getErrorMessageForCode} from "../../shared/utils/error_message_mapping";

@State<FormResponsesStateModel>({
  name: FORM_RESPONSES_STATE,
  defaults: {
    formResponses: [],
    loading: false,
    error: null
  }
})
@Injectable()
export class FormResponsesState {

  constructor(
    private messageService: MessageService,
    private formResponseService: FormResponseService
  ) {}

  @Selector()
  static formResponses(state: FormResponsesStateModel) {
      return state.formResponses
  }

  @Selector()
  static isLoading(state: FormResponsesStateModel) {
    return state.loading
  }

  @Selector()
  static error(state: FormResponsesStateModel) {
    return state.error
  }

  @Action(FormResponsesAction.List.Load)
  loadFormResponses(ctx: StateContext<FormResponsesStateModel>) {
    ctx.patchState({ loading: true, error: null, formResponses: [] });
    return this.formResponseService.getFormResponses().pipe(
      tap({
        next: (formResponses) => {
          const state = ctx.getState()
          ctx.dispatch(new FormResponsesAction.List.Loaded(formResponses))
        },
        error: (error) => {
          ctx.dispatch(new FormResponsesAction.List.LoadFailed(getErrorMessageForCode(error)))
        }
      }));
  }

    @Action(FormResponsesAction.List.Loaded)
    loadedFormResponses(ctx: StateContext<FormResponsesStateModel>, action: FormResponsesAction.List.Loaded) {
        ctx.patchState({ formResponses: action.formResponses, loading: false, error: null });
    }

    @Action(FormResponsesAction.List.LoadFailed)
    loadFailedFormResponses(ctx: StateContext<FormResponsesStateModel>, action: FormResponsesAction.List.LoadFailed) {
        ctx.patchState({ error: action.error, loading: false });
    }
}


@State<FormResponseDetailStateModel>({
  name: FORM_RESPONSE_DETAIL_STATE,
  defaults: {
    formResponse: null,
    detailedEvaluations: [],
    loading: false,
    error: null
  }
})
@Injectable()
export class FormResponsesDetailState {

  constructor(
    private messageService: MessageService,
    private formResponseService: FormResponseService
  ) {}

  @Selector()
  static formResponse(state: FormResponseDetailStateModel) {
    return state.formResponse
  }

  @Selector()
  static isLoading(state: FormResponseDetailStateModel) {
    return state.loading
  }

  @Selector()
  static error(state: FormResponseDetailStateModel) {
    return state.error
  }

  @Selector()
  static surveyScores(state: FormResponseDetailStateModel) {
    return state.formResponse?.scores || [];
  }

  @Selector()
  static surveyMaxScore(state: FormResponseDetailStateModel) {
    return state.formResponse?.form.maxScore || -1;
  }

  @Selector()
  static detailedEvaluations(state: FormResponseDetailStateModel) {
    return state.detailedEvaluations;
  }

  @Selector()
  static longestDetailedEvaluation(state: FormResponseDetailStateModel) {
    return state.detailedEvaluations ? [...state.detailedEvaluations].sort((a, b) => b.detailedEvaluations.length - a.detailedEvaluations.length)[0].detailedEvaluations : []
  }

  @Selector()
  static longestDetailedEvaluationEntriesCount(state: FormResponseDetailStateModel) {
    return state.detailedEvaluations.map(de => de.detailedEvaluations.length).reduce((a, b) => Math.max(a, b), -Infinity);
  }

  @Selector()
  static scoreTopics(state: FormResponseDetailStateModel) {
    return state.formResponse?.scores.map(score => score!.scoreTopic) || [];
  }

  @Action(FormResponsesAction.Detail.Load)
  loadFormResponse(ctx: StateContext<FormResponseDetailStateModel>, action: FormResponsesAction.Detail.Load) {
    ctx.patchState({
      loading: true,
      formResponse: null,
      detailedEvaluations: [],
      error: null
    });
    return this.formResponseService.getFormResponseById(action.id).pipe(
      tap({
        next: (formResponse) => {
          ctx.dispatch(new FormResponsesAction.Detail.Loaded(formResponse))
        },
        error: (error) => {
          ctx.dispatch(new FormResponsesAction.Detail.LoadFailed(getErrorMessageForCode(error)))
        }
      }));
  }

  @Action(FormResponsesAction.Detail.Loaded)
  loadedFormResponse(ctx: StateContext<FormResponseDetailStateModel>, action: FormResponsesAction.Detail.Loaded) {
    ctx.patchState({
      formResponse: action.formResponse,
      detailedEvaluations: this.generateDetailedEvaluations(action.formResponse),
      loading: false,
      error: null
    });
  }

  @Action(FormResponsesAction.Detail.LoadFailed)
  loadFailedFormResponse(ctx: StateContext<FormResponseDetailStateModel>, action: FormResponsesAction.Detail.LoadFailed) {
    ctx.patchState({ error: action.error, loading: false });
  }

  getVerdict(scoreTopicName: string, scorePct: number): string {
    if (scorePct < 100) {
      return `Im Bereich ${scoreTopicName} ergreifen Sie zwar erste Maßnahmen. Jedoch gibt es hier Aufholbedarf.`
    }
    return `Im Bereich ${scoreTopicName} wirtschaften Sie bereits sehr nachhaltig.`
  }

  generateDetailedEvaluations(formResponse: FormResponse): FormResponseScoreTopicDetailedEvaluation[] {
    let formResponseScoreTopicsMap = new Map<String, FormResponseScoreTopicDetailedEvaluation>();
    formResponse.scores.forEach((score) => {
      formResponseScoreTopicsMap.set(score!.scoreTopic.key, {
        scoreTopicTitle: score!.scoreTopic!.name,
        detailedEvaluations: score!.scoreTopic.detailedEvaluations.map(de => de.name),// score!.scoreTopic!.tips.map((tip) => tip!.tip),
        scorePct: score!.score / score!.requiredScore * 100,
        verdict: this.getVerdict(score!.scoreTopic.name, score!.score / score!.requiredScore * 100)
      });
    });

    /*formResponse.answers.forEach((answer) => {
      if (answer!.score != null && answer!.score! >= 0 && answer!.question!.tip != null && answer!.question!.formToScoreTopic != null) { // FixMe: answer!.score! == 0
        const scoreTopicKey = answer!.question!.formToScoreTopic.scoreTopic.key;
        const scoreTopicTips = formResponseScoreTopicsMap.get(scoreTopicKey!);
        scoreTopicTips!.tips.push(answer!.question!.tip!);
      }
    });*/

    return [...formResponseScoreTopicsMap.values()];
  }
}
