// import { UpdateSelfInput } from "../../shared/graphql/generated/graphql";

export namespace CurrentUser {
  /*export class UpdateProfile {
    static readonly type = "[CurrentUser] UpdateProfile";

    constructor(public userEditProfileInput: UpdateSelfInput) { }
  }*/

  export class LoadUser {
    static readonly type = "[CurrentUser] LoadUser";
  }
}
