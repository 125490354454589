import { Injectable } from '@angular/core';
// import { MeGQL, UserSelf } from '../graphql/generated/graphql';
import { map } from 'rxjs';
import {MeGQL, User} from "../graphql/generated/graphql";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private meGQL: MeGQL,
    // private userUpdateSelfGQL: UserUpdateSelfGQL,
  ) { }

  getUserSelf() {
    return this.meGQL.watch().valueChanges.pipe(
      map((result) => result.data.userSelf as User)
    )
  }

  /*editProfile(userUpdateSelfInput: UpdateSelfInput) {
    return this.userUpdateSelfGQL
      .mutate({ userUpdateSelfInput })
      .pipe(map((response) => response.data?.userUpdateSelf as UserSelf))
  }*/
}
