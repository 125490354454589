import {Action, createSelector, Selector, State, StateContext, Store} from "@ngxs/store";
import {AuthStateModel} from "./auth.model";
import {Injectable} from "@angular/core";
import {Auth} from "./auth.action";
import {lastValueFrom, Observable, tap} from "rxjs";
import {CurrentUser} from "../current_user/current_user.action";
import {AUTH_STATE} from "../index";
import {Storage} from "../storage/storage.actions";
import {StorageState} from "../storage/storage.state";
import {getUserClaims, isTokenValid} from "./auth.utils";
import {AuthService} from "../../shared/services/auth.service";
import {Navigate} from "@ngxs/router-plugin";
import {FormResponsesState} from "../../form-responses/state/form-responses.state";
import {FormResponsesAction} from "../../form-responses/state/form-responses.action";
import {getErrorMessageForCode} from "../../shared/utils/error_message_mapping";

@State<AuthStateModel>({
  name: AUTH_STATE,
  defaults: {
    isLoggedIn: false,
    accessToken: undefined,
    userClaims: [],
    prevFailedUrl: '',
    rememberMe: false,
    isLoggingOut: false
  }
})
@Injectable()
export class AuthState {

  constructor(
    private authService: AuthService,
    private store: Store
  ) { }

  @Selector()
  static isLoggedIn(state: AuthStateModel) {
    return state.isLoggedIn;
  }

  @Selector()
  static userClaims(state: AuthStateModel) {
    return state.userClaims;
  }

  @Selector()
  static accessToken(state: AuthStateModel) {
    return state.accessToken;
  }

  @Selector()
  static isUserAdmin(state: AuthStateModel) {
    return state.userClaims.includes('admin_access');
  }

  @Selector()
  static isUserOrgAdmin(state: AuthStateModel) {
    return state.userClaims.includes('administer_own_organisation');
  }

  @Selector()
  static isLoggingOut(state: AuthStateModel) {
    return state.isLoggingOut
  }

  static hasClaims(claims: string[]) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return claims.every(claim => state.userClaims.includes(claim));
    });
  }

  static hasClaim(claim: string) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return state.userClaims.includes(claim);
    });
  }

  @Selector()
  static prevFailedUrl(state: AuthStateModel) {
    return state.prevFailedUrl;
  }

  @Selector()
  static rememberMe(state: AuthStateModel) {
    return state.rememberMe
  }

  @Action(Auth.Initialize)
  async initializeAuth({ getState, dispatch, patchState }: StateContext<AuthStateModel>): Promise<void> {
    if (getState().isLoggedIn) {
      return;
    }

    const accessToken = getState().accessToken || this.store.selectSnapshot(StorageState.accessToken);
    console.log('InitAuth - first token check', accessToken);

    if (isTokenValid(accessToken)) {
      dispatch(Auth.LoginSuccess);
      patchState({
        userClaims: getUserClaims(accessToken)
      })
      return;
    }

    await lastValueFrom(dispatch(Auth.RefreshTokens).pipe(
      tap({
        next: () => {
          console.log('InitAuth - second token check');
          console.log(getState().accessToken);

          if (isTokenValid(getState().accessToken)) {
            patchState({
              userClaims: getUserClaims(getState().accessToken)
            })
            dispatch(Auth.LoginSuccess);
            return;
          } else {
            dispatch(Auth.Logout);
          }
        }
      })
    ));
  }

  @Action(Auth.Activateaccount)
  activateAccount({ patchState, dispatch }: StateContext<AuthStateModel>, {
    data
  }: Auth.Activateaccount): Observable<any> {
    return this.authService.activateAccount(data).pipe(
      tap({
        next: (response) => {
          if (response) {
            dispatch(new Auth.UpdateTokens({
              accessToken: response.accessToken,
              refreshToken: response.refreshToken
            }));
            dispatch(new Auth.LoginSuccess());
          }
        }
      }
      ));
  }

  @Action(Auth.Login)
  login({ patchState, dispatch }: StateContext<AuthStateModel>, { email, password, rememberMe }: Auth.Login): Observable<any> {
    patchState({ rememberMe })
    dispatch(new Storage.RememberMe(rememberMe))

    return this.authService.login({ data: { email, password }}).pipe(
      tap({
        next: (loginResponse) => {
          if (loginResponse) {
            dispatch(new Auth.UpdateTokens({
              accessToken: loginResponse.accessToken,
              refreshToken: loginResponse.refreshToken
            }));
            dispatch(new Auth.LoginSuccess());
          }
        },
        error: (error) => {
          /*if (error.graphQLErrors.length > 0) {
            switch (error.graphQLErrors[0].extensions?.code) {
              case 'UNAUTHENTICATED': {
                if (error.graphQLErrors[0].extensions?.response?.message === 'Your account is not activated. Please check your email for instructions on how to activate your account.') {
                  throw 'Ihr Account ist noch nicht aktiviert. Bitte überprüfen Sie Ihre E-Mails.'
                }
                throw 'E-Mail oder Passwort falsch. Bitte versuchen Sie es erneut.'
              }
              case 'FORBIDDEN': {
                if (error.graphQLErrors[0].extensions?.response?.message === 'Your account has been locked. Please contact support for assistance.') {
                  throw 'Ihr Account wurde gesperrt. Bitte kontaktieren Sie den Support.'
                } else if (error.graphQLErrors[0].extensions?.response?.message === 'Your organisation\'s license has expired. Please renew your license to continue using this service.') {
                  throw 'Ihre Lizenz ist abgelaufen. Bitte erneuern Sie Ihre Lizenz, um den Service weiterhin nutzen zu können.'
                }
                throw 'Nicht berechtigt. Bitte kontaktieren Sie den Support.'
              }
              case '404':
                throw 'E-Mail oder Passwort falsch. Bitte versuchen Sie es erneut.'
              case 'TOO_MANY_REQUESTS':
                throw 'Zu viele Anfragen. Bitte versuchen Sie es später erneut.'
            }
          }
          throw 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte den Support.'*/
          throw getErrorMessageForCode(error);
        }
      }));
  }

  @Action(Auth.LoginSuccess)
  loginSuccess({ patchState, dispatch }: StateContext<AuthStateModel>) {
    patchState({
      isLoggedIn: true
    })
    dispatch(CurrentUser.LoadUser);
    dispatch(FormResponsesAction.List.Load);
  }

  @Action(Auth.RefreshTokens)
  async refreshToken({ patchState, dispatch, getState }: StateContext<AuthStateModel>, { refreshToken }: Auth.RefreshTokens): Promise<any> {
    if (!refreshToken) {
      refreshToken = getState().refreshToken ?? this.store.selectSnapshot(StorageState.refreshToken);
      if (!refreshToken) {
        console.log('No refresh token found');
        return;
      }
    }

    console.log('Refresh token found', refreshToken);

    if (!isTokenValid(refreshToken)) {
      console.log('Refresh token is invalid');
      return;
    }

    return lastValueFrom(this.authService.refreshTokens(refreshToken).pipe(
      tap({
        next: (tokenPair) => {
          if (tokenPair) {
            dispatch(new Auth.UpdateTokens(tokenPair));
          }
        },
        error: (error) => {
          console.log('Refresh token error');
          console.log(error);
          dispatch(Auth.Logout);
        }
      }
      )));
  }

  @Action(Auth.Logout)
  logout({ patchState, dispatch }: StateContext<AuthStateModel>) {
    patchState({
      isLoggedIn: false,
      accessToken: undefined,
      refreshToken: undefined,
      userClaims: [],
      isLoggingOut: true
    });
    dispatch(new Storage.ClearAccessRefreshToken());
    dispatch(new Navigate(['/login']));
  }

  @Action(Auth.UpdateTokens)
  updateToken({ patchState, dispatch, getState }: StateContext<AuthStateModel>, action: Auth.UpdateTokens) {
    const { tokenPair: { accessToken, refreshToken } } = action;

    patchState({
      accessToken,
      refreshToken,
      userClaims: getUserClaims(accessToken)
    });

    console.log('Updated tokens', accessToken, refreshToken);
    console.log('Updated user claims', getUserClaims(accessToken));

    // accessToken need to be persisted so the app can keep authenticated state on page reload
    dispatch(new Storage.SetAccessToken(accessToken));

    if (getState().rememberMe) {
      dispatch(new Storage.SetRefreshToken(refreshToken));
    }
  }

  @Action(Auth.SetNewPassword)
  setNewPassword({ patchState, dispatch }: StateContext<AuthStateModel>, {
    token,
    password
  }: Auth.SetNewPassword): Observable<any> {
    return this.authService.resetPassword(token, password).pipe(
      tap({
        next: (response) => {
          if (response) {
            dispatch(new Auth.UpdateTokens({
              accessToken: response.accessToken,
              refreshToken: response.refreshToken
            }));
            dispatch(new Auth.LoginSuccess());
          }
        }
      }
      ));
  }

  @Action(Auth.NavigationFailed)
  setFailedNavUrl(ctx: StateContext<AuthStateModel>, action: Auth.NavigationFailed) {
    ctx.patchState({
      prevFailedUrl: action.url
    })
  }
}
