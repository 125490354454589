import { Action, Selector, State, StateContext } from "@ngxs/store";
import {
  CertificationApplicationFormModel, CertificationApplicationFormModelRepresentative,
  CertificationApplicationStateModel
} from "./certification-application.model";
import { Injectable } from "@angular/core";
import {
  CertificationAgreement,
  CertificationApplication,
  CertificationApplicationInput, CertificationApplicationStatus, CertificationRepresentative,
  CertificationTypeEnum, Document,
} from "../../../shared/graphql/generated/graphql";
import { CertificationApplicationAction } from "./certification-application.action";
import { CertificationService } from "../../../shared/services/certification.service";
import { catchError, tap } from "rxjs";
import { UpdateFormValue } from "@ngxs/form-plugin";
import { CertificationApplicationSection } from "../models/certification-application-section.enum";
import { MessageService } from "primeng/api";
import { CERTIFICATION_APPLICATION_STATE } from "../../../state";

@State<CertificationApplicationStateModel>({
  name: CERTIFICATION_APPLICATION_STATE,
  defaults: {
    certificationProcessId: null,
    certificationApplication: undefined,
    loading: false,
    saving: false,
    submitting: false,
    error: null,
    selectedSectionId: null,
    form: {
      dirty: false,
      status: '',
      errors: {},
      model: {
        companyInfo: {
          businessAddress: {},
        },
        structure: {},
        representatives: {
          representative: {},
          managementRepresentative: {},
          representativeIsManagementRepresentative: true,
        },
        powerOfAttorney: undefined,
        certification: {
          certificationType: CertificationTypeEnum.Initial,
        }
      }
    }
  }
})
@Injectable()
export class CertificationApplicationState {

  previousFormUpdateModel?: CertificationApplicationFormModel;

  constructor(private certificationService: CertificationService, private messageService: MessageService) {
  }

  @Selector()
  static error(state: CertificationApplicationStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: CertificationApplicationStateModel) {
    return state.loading;
  }

  @Selector()
  static saving(state: CertificationApplicationStateModel) {
    return state.saving;
  }

  @Selector()
  static submitting(state: CertificationApplicationStateModel) {
    return state.submitting;
  }

  @Selector()
  static form(state: CertificationApplicationStateModel) {
    return state.form;
  }

  @Selector()
  static certificationApplication(state: CertificationApplicationStateModel) {
    return state.certificationApplication;
  }

  @Selector()
  static applicationStatus(state: CertificationApplicationStateModel) {
    const status = state.certificationApplication?.status;
    if (!status) {
      return CertificationApplicationStatus.Draft;
    }

    if (status === CertificationApplicationStatus.Draft) {
      if (this.companyInfoSectionIsValid(state) && this.structureSectionIsValid(state) && this.representativesSectionIsValid(state) && this.certificationSectionIsValid(state)) {
        return CertificationApplicationStatus.ReadyForSubmission;
      }

      return CertificationApplicationStatus.Draft;
    }

    return state.certificationApplication!.status;
  }

  @Selector()
  static showDeclinedMessage(state: CertificationApplicationStateModel) {
    if (state.certificationApplication?.status === CertificationApplicationStatus.Declined) {
      return true;
    }

    if (state.certificationApplication?.status === CertificationApplicationStatus.Draft
        && state.certificationApplication?.previousCertificationApplication?.status === CertificationApplicationStatus.Declined) {
      return true;
    }

    return false;
  }

  @Selector()
  static certificationProcessId(state: CertificationApplicationStateModel) {
    return state.certificationProcessId;
  }

  @Selector()
  static selectedSectionId(state: CertificationApplicationStateModel) {
    return state.selectedSectionId;
  }

  @Selector()
  static companyInfoSectionIsValid(state: CertificationApplicationStateModel) {
    if (!state.form.model.companyInfo.companyName) {
      return false;
    }

    if (!state.form.model.companyInfo.businessAddress
      || !state.form.model.companyInfo.businessAddress.street
      || !state.form.model.companyInfo.businessAddress.houseNumber
      || !state.form.model.companyInfo.businessAddress.zipCode
      || !state.form.model.companyInfo.businessAddress.city
      || !state.form.model.companyInfo.businessAddress.country) {
      return false;
    }

    if (!state.form.model.companyInfo.proofOfRegistrationDocument) {
      return false;
    }

    return true;
  }

  @Selector()
  static structureSectionIsValid(state: CertificationApplicationStateModel) {
    if (state.form.model.structure.professionId == null || (state.form.model.structure.professionId === 999 && !state.form.model.structure.professionOther)) {
      return false;
    }

    return true;
  }

  @Selector()
  static representativesSectionIsValid(state: CertificationApplicationStateModel) {
    if (!CertificationApplicationState.representativeFormIsValid(state.form.model.representatives.representative)) {
      return false;
    }

    if (!state.form.model.representatives.representativeIsManagementRepresentative && (!CertificationApplicationState.representativeFormIsValid(state.form.model.representatives.managementRepresentative || !state.form.model.representatives.representativeLegitimizationDocument)) && !state.form.model.powerOfAttorney) {
      return false;
    }

    return true;
  }

  static representativeFormIsValid(representativeForm: CertificationApplicationFormModelRepresentative) {
    return representativeForm.firstName && representativeForm.lastName && representativeForm.email && representativeForm.phone;
  }

  @Selector()
  static certificationSectionIsValid(state: CertificationApplicationStateModel) {
    return state.form.model.certification.certificationType != null && state.form.model.certification.certificationAgreementDocument != null;
  }

  @Action(CertificationApplicationAction.Loaded)
  loaded(ctx: StateContext<CertificationApplicationStateModel>, { certificationProcessId, certificationApplication }: CertificationApplicationAction.Loaded) {
    const model = this._generateCertificationApplicationFormModel(certificationApplication);
    this.previousFormUpdateModel = model;

    ctx.patchState({
      certificationProcessId,
      certificationApplication,
      form: {
        model: model,
        dirty: false,
        status: '',
        errors: {}
      }
    });

    const state = ctx.getState();
    if (state.selectedSectionId == null) {
      ctx.dispatch(new CertificationApplicationAction.SelectSection(this._getFirstIncompleteSectionId(state)));
    }
  }

  private _generateCertificationApplicationFormModel(certificationApplication?: CertificationApplication): CertificationApplicationFormModel {
    return {
      companyInfo: {
        companyName: certificationApplication?.companyName as string || undefined,
        website: certificationApplication?.website as string || undefined,
        businessAddress: {
          street: certificationApplication?.businessAddress?.street || undefined,
          houseNumber: certificationApplication?.businessAddress?.houseNumber || undefined,
          zipCode: certificationApplication?.businessAddress?.zip || undefined,
          city: certificationApplication?.businessAddress?.city || undefined,
          state: certificationApplication?.businessAddress?.state || undefined,
          country: certificationApplication?.businessAddress?.country || undefined
        },
        proofOfRegistrationDocument: certificationApplication?.proofOfRegistrationDocument as Document || undefined,
        employeesAtLocations: certificationApplication?.employeesAtLocations as string || undefined,
        taxNumber: certificationApplication?.taxNumber as string || undefined
      },
      structure: {
        professionId: certificationApplication?.professionId as number || undefined,
        professionOther: certificationApplication?.professionOther as string || undefined,
        organisationDiagramDocument: certificationApplication?.organisationDiagramDocument as Document || undefined,
        operatesInExcludedSectors: certificationApplication?.operatesInExcludedSectors as boolean || false,
        legalObligations: certificationApplication?.legalObligations as string || undefined,
        affiliationToOtherCompanies: certificationApplication?.affiliationToOtherCompanies as string || undefined,
        outsourcedProcedures: certificationApplication?.outsourcedProcedures as string || undefined,
        majorContractors: certificationApplication?.majorContractors as string || undefined
      },
      representatives: {
        representative: this._getRepresentativeFormFromCertificationRepresentative(certificationApplication?.representative as CertificationRepresentative | undefined),
        managementRepresentative: this._getRepresentativeFormFromCertificationRepresentative(certificationApplication?.representativeId != certificationApplication?.managementRepresentativeId ? certificationApplication?.managementRepresentative as CertificationRepresentative : undefined),
        representativeIsManagementRepresentative: certificationApplication?.representativeId == certificationApplication?.managementRepresentativeId,
        representativeLegitimizationDocument: certificationApplication?.representativeLegitimizationDocument as Document || undefined
      },
      powerOfAttorney: certificationApplication?.powerOfAttorney,
      certification: {
        certificationType: certificationApplication?.certificationType as CertificationTypeEnum || undefined,
        desiredCertificationDate: certificationApplication?.desiredCertificationDate as Date || undefined,
        certificationAgreement: certificationApplication?.currentCertificationAgreement as CertificationAgreement || undefined,
        certificationAgreementDocument: certificationApplication?.currentCertificationAgreement?.document as Document || undefined
      }
    } as CertificationApplicationFormModel;
  }

  private _getRepresentativeFormFromCertificationRepresentative(representative: CertificationRepresentative | undefined) {
    return {
      firstName: representative?.firstName as string || undefined,
      lastName: representative?.lastName as string || undefined,
      email: representative?.email as string || undefined,
      fax: representative?.fax as string || undefined,
      phone: representative?.phone as string || undefined,
    }
  }

  private _getFirstIncompleteSectionId(state: CertificationApplicationStateModel) {
    if (!CertificationApplicationState.companyInfoSectionIsValid(state)) {
      return CertificationApplicationSection.companyInfo;
    }

    if (!CertificationApplicationState.structureSectionIsValid(state)) {
      return CertificationApplicationSection.structure;
    }

    if (!CertificationApplicationState.representativesSectionIsValid(state)) {
      return CertificationApplicationSection.representatives;
    }

    if (!CertificationApplicationState.certificationSectionIsValid(state)) {
      return CertificationApplicationSection.certification
    }

    return CertificationApplicationSection.certification;
  }

  @Action(UpdateFormValue, { cancelUncompleted: true })
  updateFormValue(ctx: StateContext<CertificationApplicationStateModel>, { payload }: UpdateFormValue) {
    if (payload.path != 'certificationApplication.form') {
      return;
    }

    if (!this.previousFormUpdateModel || this.isFormUpdated(this.previousFormUpdateModel, payload.value)) {
      return ctx.dispatch(new CertificationApplicationAction.Save);
    }
    return;
  }

  isFormUpdated(originalForm: CertificationApplicationFormModel, updatedForm: CertificationApplicationFormModel): boolean {
    const eq = !this.isDeepEqual(originalForm, updatedForm);
    console.log('isFormUpdated', eq);
    return eq;
  }

  private isDeepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !this.isDeepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }


  @Action(CertificationApplicationAction.Save, { cancelUncompleted: true })
  save(ctx: StateContext<CertificationApplicationStateModel>) {
    const applicationStatus = ctx.getState().certificationApplication?.status;
    if (applicationStatus === CertificationApplicationStatus.Submitted || applicationStatus === CertificationApplicationStatus.Approved) {
      return;
    }

    this.messageService.clear( 'application-save');
    if (!ctx.getState().saving) {
      this.messageService.add({key: 'application-save', severity: 'info', summary: 'Wird gespeichert...', sticky: true });
    }
    ctx.patchState({
      saving: true
    });
    return this.certificationService.certificationApplicationEdit({
      certificationProcessId: ctx.getState().certificationProcessId!,
      data: this._generateCertificationApplicationInputDataPayload(ctx.getState().form.model)
    }).pipe(
      tap(() => {
        ctx.patchState({
          saving: false
        });
        this.messageService.clear( 'application-save');
      }),
      tap({
        next: (certificationApplication) => {
          // this.messageService.add({ key: 'application-save', severity: 'success', summary: 'Gespeichert', detail: 'Ihre Daten wurden gespeichert.', life: 1000 });
          ctx.patchState({
            certificationApplication
          });
        },
        error: (error) => {
          console.error(error);
          this.messageService.add({ key: 'application-save', severity: 'error', summary: 'Fehler', detail: 'Ihre Daten konnten nicht gespeichert werden.' });
        }
      })
    )
  }

  private _generateCertificationApplicationInputDataPayload(form: CertificationApplicationFormModel): CertificationApplicationInput {
    return {
      companyName: form.companyInfo.companyName,
      professionId: form.structure.professionId,
      professionOther: form.structure.professionOther,
      website: form.companyInfo.website,
      businessAddress: {
        street: form.companyInfo.businessAddress.street,
        houseNumber: form.companyInfo.businessAddress.houseNumber,
        zip: form.companyInfo.businessAddress.zipCode,
        city: form.companyInfo.businessAddress.city,
        state: form.companyInfo.businessAddress.state,
        country: form.companyInfo.businessAddress.country
      },
      representativeIsManagementRepresentative: form.representatives.representativeIsManagementRepresentative,
      representative: {
        firstName: form.representatives.representative.firstName,
        lastName: form.representatives.representative.lastName,
        email: form.representatives.representative.email,
        phone: form.representatives.representative.phone,
        fax: form.representatives.representative.fax,
      },
      managementRepresentative: !form.representatives.representativeIsManagementRepresentative ? {
        firstName: form.representatives.managementRepresentative.firstName,
        lastName: form.representatives.managementRepresentative.lastName,
        email: form.representatives.managementRepresentative.email,
        phone: form.representatives.managementRepresentative.phone,
        fax: form.representatives.managementRepresentative.fax,
      } : null,
      powerOfAttorney: form.powerOfAttorney,
      certificationType: form.certification.certificationType,
      desiredCertificationDate: form.certification.desiredCertificationDate,
      taxNumber: form.companyInfo.taxNumber,
      employeesAtLocations: form.companyInfo.employeesAtLocations,
      operatesInExcludedSectors: form.structure.operatesInExcludedSectors,
      legalObligations: form.structure.legalObligations,
      affiliationToOtherCompanies: form.structure.affiliationToOtherCompanies,
      outsourcedProcedures: form.structure.outsourcedProcedures,
      majorContractors: form.structure.majorContractors,
    } as CertificationApplicationInput;
  }

  @Action(CertificationApplicationAction.SelectSection)
  selectSection(ctx: StateContext<CertificationApplicationStateModel>, { sectionId }: CertificationApplicationAction.SelectSection) {
    ctx.patchState({
      selectedSectionId: sectionId
    });
  }

  @Action(CertificationApplicationAction.Submit)
  submit(ctx: StateContext<CertificationApplicationStateModel>) {
    ctx.patchState({
      submitting: true
    });
    return this.certificationService.certificationApplicationSubmit(ctx.getState().certificationProcessId!).pipe(
      tap(() => {
        ctx.patchState({
          submitting: false
        });
      }),
      tap({
        next: (certificationApplication) => {
          ctx.patchState({
            certificationApplication
          });
          this.messageService.add({ key: 'application-submit', severity: 'success', summary: 'Erfolgreich übermittelt', detail: 'Ihre Daten wurden übermittelt.', life: 1000 });
        },
        error: (error) => {
          console.error(error);
          this.messageService.add({ key: 'application-submit', severity: 'error', summary: 'Fehler', detail: 'Ihre Daten konnten nicht übermittelt werden.' });
        }
      })
    )
  }

  @Action(CertificationApplicationAction.RemoveAgreement)
  removeAgreement(ctx: StateContext<CertificationApplicationStateModel>) {
    console.log('remove agreement');
    const currentApplication = ctx.getState().certificationApplication;
    const applicationWithRemovedAgreement = {
      ...currentApplication,
      currentCertificationAgreement: undefined
    } as CertificationApplication;

    ctx.patchState({
      certificationApplication: applicationWithRemovedAgreement
    });
  }
}
