import { CertificationApplication } from "../../../shared/graphql/generated/graphql";

export namespace CertificationApplicationAction {

  export class Loading {
    static readonly type = "[CertificationApplication] Loading";
  }

  export class Loaded {
    static readonly type = "[CertificationApplication] Loaded";
    constructor(public certificationProcessId: string, public certificationApplication?: CertificationApplication) {}
  }

  export class Error {
    static readonly type = "[CertificationApplication] Error";

    constructor(public error: string) {}
  }

  export class Update {
    static readonly type = "[CertificationApplication] Update";
    constructor(public certificationApplication: CertificationApplication) {}
  }

  export class SelectSection {
    static readonly type = "[CertificationApplication] SelectSection";

    constructor(public sectionId: string) {
    }
  }

  export class Save {
    static readonly type = "[CertificationApplication] Save";
  }

  export class Submit {
    static readonly type = "[CertificationApplication] Submit";
  }

  export class RemoveAgreement {
    static readonly type = "[CertificationApplication] RemoveAgreement";
  }

}
