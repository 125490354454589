import {CertificationBaseInfo, CertificationProcess, Document} from "../../shared/graphql/generated/graphql";

export namespace CertificationAction {

  export class SetProcessAndInitialize {
    static readonly type = "[Certification] SetProcessAndInitialize";
    constructor(public certificationProcessId: string) {}
  }

  export class Initialize {
    static readonly type = "[Certification] Initialize";
    constructor() {}
  }

  export class LoadProcess {
    static readonly type = "[Certification] Load";
  }

  export class SetLoaded {
    static readonly type = "[Certification] SetLoaded";
    constructor(public certificationProcess: CertificationProcess) {}
  }

  export class Error {
    static readonly type = "[Certification] Error";
    constructor(public error: string) {}
  }

  export class SetBaseInfo {
    static readonly type = "[Certification] SetBaseInfo";
    constructor(public baseInfo: CertificationBaseInfo) {}
  }

  export class AddDocument {
    static readonly type = "[Certification] AddDocument";
    constructor(public document: Document) {}
  }

  export class RemoveDocument {
    static readonly type = "[Certification] RemoveDocument";
    constructor(public documentId: string) {}
  }


  export namespace Verification {
    export class SelectFormQuestion {
      static readonly type = "[Certification] Verification.SelectFormQuestion";
      constructor(public formQuestionId: string | null) {}
    }

    export class CreateOrUpdateAnswerVerification {
      static readonly type = "[Certification] Verification.CreateOrUpdateAnswerVerification";
      constructor(public payload: {
        answerId: string,
        verificationComment?: string,
        documentId?: string,
        documentTypeId?: number,
      }) {}
    }

    export class Submit {
      static readonly type = "[Certification] Verification.Submit";
    }
  }

}
