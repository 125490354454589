import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/service/app.layout.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

  @ViewChild('menubutton') menuButton!: ElementRef;

  constructor(public layoutService: LayoutService, private router: Router) { }

  onMenuButtonClick() {
    // this.layoutService.onMenuToggle();
    this.router.navigateByUrl('/')
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }

}
