import { Action, Selector, State, StateContext } from "@ngxs/store";
import { SurveyStateModel } from "./survey.model";
import { SURVEY_STATE } from '../index';
import { Injectable } from "@angular/core";
import { SurveyStateDefaults } from "./survey.state.defaults";
import {UserSelfAction} from "../user_self/user_self.action";
import {UserSelfStateModel} from "../user_self/user_self.model";
import {SurveyAction} from "./survey.action";
import {SurveyService} from "../../shared/services/survey.service";
import {MessageService} from "primeng/api";

@State<SurveyStateModel>({
  name: SURVEY_STATE,
  defaults: SurveyStateDefaults
})
@Injectable()
export class SurveyState {

  constructor(
    private surveyService: SurveyService,
    private messageService: MessageService
  ) { }

  @Selector()
  static professionsByIndustry(state: SurveyStateModel) {
    return (industry: string) => state.professionsByIndustry![industry]
  }

  @Selector()
  static formByIndustry(state: SurveyStateModel) {
    return (industry: string) => state.formByIndustry![industry]
  }

  @Action(SurveyAction.RequestAudit)
  requestAudit(ctx: StateContext<UserSelfStateModel>, action: UserSelfAction.RequestAudit) {
    return this.surveyService.requestAudit(action.requestAuditInput)
  }
}
