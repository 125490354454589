/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { RootModule } from './app/root/root.module';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID} from "@angular/core";


platformBrowserDynamic().bootstrapModule(RootModule, {
  providers: [
  ]
})
  .catch(err => console.error(err));
