import {
  CreateProfessionAuditRequestInput,
  User,
} from "src/app/shared/graphql/generated/graphql";

export namespace SurveyAction {
  export class RequestAudit {
    static readonly type = "[Survey] RequestAudit";
    constructor(public requestAuditInput: CreateProfessionAuditRequestInput) { }
  }
}
