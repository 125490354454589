import jwtDecode, { JwtPayload } from "jwt-decode";

export function isTokenValid(token: string = ''): boolean {
  if (token === '') return false

  const decoded = jwtDecode<JwtPayload>(token);
  if (!decoded.exp) return false;

  return decoded.exp * 1000 >= new Date().getTime()
}

export function getUserClaims(accessToken?: string): string[] {
  if (!accessToken) return [];

  const payload = jwtDecode<JwtPayload & { claims: string[] }>(accessToken);
  return payload.claims;
}
