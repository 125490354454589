import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {CurrentUserStateModel} from "./current_user.model";
import {CurrentUser} from "./current_user.action";
import {CURRENT_USER_STATE} from "../index";
import {UserService} from "../../shared/services/user.service";
import {tap} from "rxjs";
import {MessageService} from "primeng/api";
import {CertificationAction} from "../../certification/state/certification.action";

@State<CurrentUserStateModel>({
  name: CURRENT_USER_STATE,
  defaults: {}
})
@Injectable()
export class CurrentUserState {

  constructor(
    private userService: UserService,
    private messageService: MessageService
  ) {
  }

  @Selector()
  static profile(state: CurrentUserStateModel) {
    return state.profile;
  }

  @Selector()
  static fullName(state: CurrentUserStateModel) {
    return `${state.profile?.firstName} ${state.profile?.lastName}`;
  }

  @Selector()
  static userId(state: CurrentUserStateModel) {
    return state.userId;
  }

  @Selector()
  static companyName(state: CurrentUserStateModel): string | undefined {
    return state.profile?.companyName ?? undefined;
  }

  @Selector()
  static certificationProcessId(state: CurrentUserStateModel): string | undefined {
    return state.profile?.certificationProcess?.id ?? undefined;
  }

  @Action(CurrentUser.LoadUser)
  loadUser({ patchState, dispatch }: StateContext<CurrentUserStateModel>) {
    this.userService.getUserSelf().subscribe(
      (userSelf) => {
        if (userSelf) {
          patchState({
            profile: userSelf,
            userId: userSelf.id
          });
          if (userSelf.certificationProcess) {
            dispatch(new CertificationAction.SetProcessAndInitialize(userSelf.certificationProcess.id));
          }
          console.log('UserSelf', userSelf);
        } else {
          console.log('No user data');
        }
      },
    );
  }
}
