export const USER_SELF_STATE = "user_self";
export const SURVEY_STATE = 'survey';
export const AUTH_STATE = "auth";
export const ROOT_STATE = "root";
export const CURRENT_USER_STATE = "currentUser";
export const STORAGE_STATE = "storage";

export const FORM_RESPONSES_STATE = "formResponses";
export const FORM_RESPONSE_DETAIL_STATE = "formResponseDetail";

export const CHECKOUT_STATE = "checkout";

export const CERTIFICATION_STATE = "certification";

export const CERTIFICATION_APPLICATION_STATE = "certificationApplication";

export const CERTIFICATION_BASE_INFO_STATE = "certificationBaseInfo";


