import { ApolloError } from "@apollo/client/errors";


export enum ErrorCode {
  UNAUTHORIZED = 'UNAUTHORIZED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_ALREADY_ACTIVATED = 'USER_ALREADY_ACTIVATED',
  USER_NOT_ACTIVATED = 'USER_NOT_ACTIVATED',
  CURRENT_PASSWORD_INCORRECT = 'CURRENT_PASSWORD_INCORRECT',
  UNABLE_TO_SET_BASE_DATA = 'UNABLE_TO_SET_BASE_DATA',
  ID_CANNOT_BE_NULL = 'ID_CANNOT_BE_NULL',
  DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
  FORM_NOT_FOUND = 'FORM_NOT_FOUND',
  FORM_RESPONSE_NOT_FOUND = 'FORM_RESPONSE_NOT_FOUND',
  FORBIDDEN = 'FORBIDDEN',
  BAD_REQUEST = 'BAD_REQUEST',
  REQUIRED_FIELDS_MISSING = 'REQUIRED_FIELDS_MISSING',
  NO_SUCCESSFUL_PAYMENT = 'NO_SUCCESSFUL_PAYMENT',
  NO_CERTIFICATION_PROCESS_FOUND = 'NO_CERTIFICATION_PROCESS_FOUND',
  SCORE_NOT_SUFFICIENT = 'SCORE_NOT_SUFFICIENT',
  PROFESSION_AUDIT_REQUEST_NOT_FOUND = 'PROFESSION_AUDIT_REQUEST_NOT_FOUND',
  CERTIFICATE_TYPE_NOT_FOUND = 'CERTIFICATE_TYPE_NOT_FOUND',
  VERIFICATION_PROCESS_ALREADY_EXISTS = 'VERIFICATION_PROCESS_ALREADY_EXISTS',
  REQUIRED_DOCUMENTS_MISSING = 'REQUIRED_DOCUMENTS_MISSING',
}

export function getErrorMessageForCode(error: ApolloError): string {
  if (error.graphQLErrors.length === 0) return 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.';
  let errorCode = error.graphQLErrors[0].extensions['code'];
  if (errorCode) {
    errorCode = errorCode as ErrorCode;
    switch (errorCode) {
      case ErrorCode.UNAUTHORIZED: {
        return 'E-Mail oder Passwort falsch. Bitte versuchen Sie es erneut.';
      }
      case ErrorCode.USER_NOT_FOUND: {
        return 'Nutzer nicht gefunden. Bitte versuchen Sie es erneut.';
      }
      case ErrorCode.USER_ALREADY_ACTIVATED: {
        return 'Ihr Account ist bereits aktiviert. Bitte loggen Sie sich ein.';
      }
      case ErrorCode.USER_NOT_ACTIVATED: {
        return 'Ihr Account ist noch nicht aktiviert. Bitte überprüfen Sie Ihre E-Mails.';
      }
      case ErrorCode.CURRENT_PASSWORD_INCORRECT: {
        return 'Das eingegebene Passwort ist falsch. Bitte versuchen Sie es erneut.';
      }
      case ErrorCode.UNABLE_TO_SET_BASE_DATA: {
        return 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.';
      }
      case ErrorCode.ID_CANNOT_BE_NULL: {
        return 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.';
      }
      case ErrorCode.DOCUMENT_NOT_FOUND: {
        return 'Dokument wurde nicht gefunden. Bitte versuche es erneut.';
      }
      case ErrorCode.FORM_NOT_FOUND: {
        return 'Formular wurde nicht gefunden. Bitte versuche es erneut.';
      }
      case ErrorCode.FORM_RESPONSE_NOT_FOUND: {
        return 'Antwort wurde nicht gefunden. Bitte versuche es erneut.';
      }
      case ErrorCode.FORBIDDEN: {
        return 'Sie haben nicht die nötigen Berechtigungen, um diese Aktion auszuführen.';
      }
      case ErrorCode.BAD_REQUEST: {
        return 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.';
      }
      case ErrorCode.REQUIRED_FIELDS_MISSING: {
        return 'Es sind nicht alle benötigten Felder ausgefüllt. Bitte versuche es erneut.';
      }
      case ErrorCode.NO_SUCCESSFUL_PAYMENT: {
        return 'Es konnte keine erfolgreiche Zahlung gefunden werden. Bitte versuche es erneut.';
      }
      case ErrorCode.NO_CERTIFICATION_PROCESS_FOUND: {
        return 'Es konnte kein Zertifizierungsprozess gefunden werden. Bitte versuche es erneut.';
      }
      case ErrorCode.SCORE_NOT_SUFFICIENT: {
        return 'Ihr Score ist nicht ausreichend.';
      }
      case ErrorCode.PROFESSION_AUDIT_REQUEST_NOT_FOUND: {
        return 'Audit Request existiert nicht.';
      }
      case ErrorCode.CERTIFICATE_TYPE_NOT_FOUND: {
        return 'Zertifikatstyp existiert nicht.';
      }
      case ErrorCode.VERIFICATION_PROCESS_ALREADY_EXISTS: {
        return 'Sie haben bereits einen aktiven Verifizierungsprozess. Bitte schließen Sie diesen ab, bevor Sie einen neuen starten.';
      }
      case ErrorCode.REQUIRED_DOCUMENTS_MISSING: {
        return 'Es fehlen noch Dokumente. Bitte laden Sie diese hoch, um den Prozess fortzusetzen.';
      }
    }
  }
  return 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.';
}
