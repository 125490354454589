import { SurveyStateModel } from "./survey.model";

export const SurveyStateDefaults: SurveyStateModel = {

  formByIndustry: {
    solo: 'DYZ35T35',
    service: 'S3z8Oc3A',
    food: 'lNzESKmv',
    test: 'vfxGVOKu'
  },

  professionsByIndustry: {
    solo: [
      {
        group: 'Rechts-, Steuer- und wirtschaftsberatende Berufe',
        items: [
          { name: "Anwältin*Anwalt" },
          { name: "Beratende*r Volks- / Betriebswirt*in" },
          { name: "Buchführungshelfer*in" },
          { name: "Buchhalter*in" },
          { name: "Buchprüfer*in / Bücherrevisor*in" },
          { name: "Exportberater*in" },
          { name: "Finanz- und Kreditberater*in" },
          { name: "Kreditberater" },
          { name: "Künstler-Manager" },
          { name: "Kursmakler" },
          { name: "Notar*in" },
          { name: "Public-Relations-(PR-)Berater" },
          { name: "Steuerberater*in" },
          { name: "Steuerbevollmächtigte*r" },
          { name: "Unternehmensberater*in" },
          { name: "Wirtschaftsprüfer*in" },
        ]
      },
      {
        group: 'Naturwissenschaftliche/ technische Berufe',
        items: [
          { name: "Architekt*in" },
          { name: "Bauingenieur*in" },
          { name: "Baustatiker*in" },
          { name: "EDV-Berater*in" },
          { name: "Ingenieur*in" },
          { name: "Innenarchitekt*in" },
          { name: "Lotsin*Lotse" },
          { name: "Projektierer*in" },
          { name: "Vermessungsingenieur*in" },
          { name: "Versicherungsmathematiker*in" },
          { name: "Wissenschaftler*in" },
        ]
      },
      {
        group: "Informationsvermittelnden und sprachlichen Berufe",
        items: [
          { name: "Bildberichterstatter*in" },
          { name: "Dolmetscher*in" },
          { name: "Sachverständige*r" },
          { name: "Interviewer*in" },
          { name: "Journalist*in" },
          { name: "Kamerafrau*Kameramann" },
          { name: "Lehrer*in" },
          { name: "Trainer*in" },
          { name: "Übersetzer*in" },
        ]
      },
      {
        group: "Künstlerische Berufe / Kreativwirtschaft",
        items: [
          { name: "Artist*in" },
          { name: "Designer*in" },
          { name: "Drehbuchautor*in" },
          { name: "Fotograf*in" },
          { name: "Fotomodell" },
          { name: "Filmhersteller*in" },
          { name: "Grafiker*in" },
          { name: "Klavierstimmer*in" },
          { name: "Komponist*innen" },
          { name: "Künstler*in" },
          { name: "Maler*in (künstlerisch)" },
          { name: "Musiker*in" },
          { name: "Musikbearbeiter*innen" },
          { name: "Regisseur*in" },
          { name: "Schriftsteller*in" },
          { name: "Tontechniker*in" },
        ]
      },
      {
        group: 'Nicht dabei?',
        items: [{ name: 'Sonstige' }]
      }
    ],
    service: [
      {
        group: 'Erbringung v. freiberuflichen, wissenschaftlichen u. technischen Dienstleistungen (M)*',
        items: [
          { name: "Architekturbüros f. Hochbau" },
          { name: "Buchführung (ohne Datenverarbeitungsdienste)" },
          { name: "Büro f. Innenarchitektur" },
          { name: "Dolmetschen" },
          { name: "Erbringung sonstiger juristischer Dienstleistungen a.n.g." },
          { name: "Fotografie" },
          { name: "Grafik- u. Kommunikationsdesign" },
          { name: "Industrie-, Produkt- u. Mode-Design" },
          { name: "Interior Design u. Raumgestaltung" },
          { name: "Notariat" },
          { name: "Patentanwaltskanzlei" },
          { name: "Praxen v. vereidigten Buchprüferinnen u. –prüfern, Buchprüfungsgesellschaften" },
          { name: "Praxis v. Wirtschaftsprüfer*innen, Wirtschaftsprüfungsgesellschaften" },
          { name: "Praxis v. Steuerbevollmächtigten, Steuerberater*innen, Steuerberatungsgesellschaften" },
          { name: "Public-Relations-Beratung" },
          { name: "Rechtsanwaltskanzlei" },
          { name: "Übersetzen" },
          { name: "Unternehmensberatung" },
          { name: "Werbeagentur" },
        ]
      },
      {
        group: 'KMU aus dem Dienstleistungssektor (J,K,R)*',
        items: [
          { name: "H. v. Filmen, Videofilmen u. Fernsehprogrammen" },
          { name: "Nachbearbeitung u. sonstige Filmtechnik" },
          { name: "Entwicklung u. Programmierung v. Internetpräsentationen" },
          { name: "Erbringung v. Beratungsleistungen auf dem Gebiet der Informationstechnologie" },
          { name: "Tätigkeit v. Versicherungsmakler*innen" },
          { name: "Künstlerisches u. schriftstellerisches Schaffen" },
        ]
      },
      {
        group: 'Nicht dabei?',
        items: [{ name: 'Sonstige' }]
      }
    ],
    food: [
      {
        group: 'KMU aus dem Dienstleistungssektor (I)*',
        items: [
          { name: "Ausschank v. Getränken" },
          { name: "Bar" },
          { name: "Café" },
          { name: "Diskothek / Tanzlokal" },
          { name: "Eissalon" },
          { name: "Essen auf Rädern" },
          { name: "Event-Caterer" },
          { name: "Eventcooking" },
          { name: "Gaststätte" },
          { name: "Imbissstube" },
          { name: "Kantine" },
          { name: "Restaurant mit herkömmlicher Bedienung" },
          { name: "Restaurant mit Selbstbedienung" },
          { name: "Schankwirtschaften" },
          { name: "Schulkantine" },
          { name: "Shisha Bar" },
          { name: "Vergnügungslokal" },
        ]
      },
      {
        group: 'Nicht dabei?',
        items: [{ name: 'Sonstige' }]
      }
    ],
    test: [
      {
        group: 'Test',
        items: [
          { name: "Test" },
        ]
      },
      {
        group: 'Nicht dabei?',
        items: [{ name: 'Sonstige' }]
      }
    ]
  }

}
