import {Injectable, NgZone} from "@angular/core";
import {Action, createSelector, Selector, State, StateContext, Store} from "@ngxs/store";
import {Message, MessageService} from "primeng/api";
import {tap} from "rxjs";
import {CertificationStateModel} from "./certification.model";
import {CERTIFICATION_STATE} from "../../state";
import {CertificationAction} from "./certification.action";
import {CheckoutService} from "../../shared/services/checkout.service";
import {
  CertificationApplication,
  CompanyType,
  FormQuestion,
  FormResponseAnswer,
  VerificationRequestStatus
} from "../../shared/graphql/generated/graphql";
import {CertificationService} from "../../shared/services/certification.service";
import {getQuestionStatus} from "./certification.utils";
import {getErrorMessageForCode} from "../../shared/utils/error_message_mapping";
import { CertificationApplicationState } from "../application/state/certification-application.state";
import { CertificationApplicationAction } from "../application/state/certification-application.action";


export enum FormQuestionWithAnswersStatus {
  Initial = 'INITIAL',
  Incomplete = 'INCOMPLETE',
  Complete = 'COMPLETE',
  Verified = 'VERIFIED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
  Unknown = 'UNKNOWN',
}

export interface FormQuestionWithAnswers {
  question: FormQuestion
  answers: FormResponseAnswer[]
  status: FormQuestionWithAnswersStatus
}


@State<CertificationStateModel>({
  name: CERTIFICATION_STATE,
  defaults: {
    certificationProcessId: null,
    verificationRequest: null,
    certificationProcess: null,
    formResponseAnswers: [],
    documents: [],
    selectedFormQuestionId: null,
    error: null,
    loading: false,
  }
})
@Injectable()
export class CertificationState {

  stripe: any;

  constructor(
    private messageService: MessageService,
    private certificationService: CertificationService,
    private store: Store,
    private checkoutService: CheckoutService,
    private zone: NgZone
  ) {

  }

  @Selector()
  static certificationProcessId(state: CertificationStateModel) {
    return state.certificationProcessId;
  }

  @Selector()
  static companyType(state: CertificationStateModel) {
    return (state.certificationProcess?.companyType as CompanyType | undefined) || null;
  }

  @Selector()
  static documents(state: CertificationStateModel) {
    return state.documents;
  }

  @Selector()
  static certificationProcess(state: CertificationStateModel) {
    return state.certificationProcess;
  }

  @Selector()
  static formResponseAnswers(state: CertificationStateModel)  {
    return state.formResponseAnswers;
  }

  @Selector([CertificationState.formResponseAnswers, CertificationState.certificationProcess])
  static formQuestionsWithAnswers(state: CertificationStateModel) {
    if (state.certificationProcess == null) {
      return [] as FormQuestionWithAnswers[];
    }
    let questions: FormQuestionWithAnswers[] = state.certificationProcess.formResponse.form.questions.filter((question) => question!.verificationRequired).map(question => {
      return {
        question,
        answers: [],
        status: FormQuestionWithAnswersStatus.Initial,
      } as FormQuestionWithAnswers;
    })

    questions.sort((a, b) => a.question.index - b.question.index);

    state.formResponseAnswers.forEach(answer => {
      const question = questions.find(question => question.question.id === answer.questionId);
      if (question) {
        question.answers.push(answer);
      }
    });


    for (let question of questions) {
      question.status = getQuestionStatus(question.question, question.answers);
    }


    return questions;
  }

  @Selector()
  static selectedFormQuestionId(state: CertificationStateModel) {
    return state.selectedFormQuestionId;
  }

  @Selector()
  static selectedFormQuestionWithAnswers(state: CertificationStateModel) {
    const question = state.certificationProcess?.formResponse.form.questions.find(question => question!.id === state.selectedFormQuestionId);
    if (question == null) {
      return null;
    }
    const answers = state.formResponseAnswers.filter(answer => answer.questionId === question.id);
    return {
      question,
      answers,
      status: getQuestionStatus(question, answers)
    } as FormQuestionWithAnswers;
  }

  static formResponseAnswerById(answerId: string) {
    return createSelector([CertificationState], (state: CertificationStateModel) => {
      return state.formResponseAnswers.find(answer => answer.id === answerId);
    });
  }

  @Selector([CertificationState.formQuestionsWithAnswers])
  static incompleteCount(state: CertificationStateModel, questions: FormQuestionWithAnswers[]): number {
    if (questions.length === 0) {
      return 0;
    }

    const questionStatus = questions.map(question => question.status);
    return questionStatus.filter(status => [FormQuestionWithAnswersStatus.Incomplete, FormQuestionWithAnswersStatus.Rejected, FormQuestionWithAnswersStatus.Initial].includes(status)).length;
  }

  @Selector([CertificationState.formQuestionsWithAnswers])
  static completeCount(state: CertificationStateModel, questions: FormQuestionWithAnswers[]): number {
    if (questions.length === 0) {
      return 0;
    }

    const questionStatus = questions.map(question => question.status);
    return questionStatus.filter(status => [FormQuestionWithAnswersStatus.Complete, FormQuestionWithAnswersStatus.Verified].includes(status)).length;
  }

  @Selector([CertificationState.formQuestionsWithAnswers])
  static questionsCount(state: CertificationStateModel, questions: FormQuestionWithAnswers[]): number {
    return questions.length;
  }

  @Selector([CertificationState.formQuestionsWithAnswers])
  static processStatus(state: CertificationStateModel, questions: FormQuestionWithAnswers[]): FormQuestionWithAnswersStatus {
    if (questions.length === 0) {
      return FormQuestionWithAnswersStatus.Unknown;
    }

    const questionStatus = questions.map(question => question.status);

    if (questionStatus.includes(FormQuestionWithAnswersStatus.Incomplete)) {
      return FormQuestionWithAnswersStatus.Incomplete;
    } else if (questionStatus.includes(FormQuestionWithAnswersStatus.Rejected)) {
      return FormQuestionWithAnswersStatus.Rejected;
    } else if (questionStatus.every(status => status === FormQuestionWithAnswersStatus.Verified)) {
      return FormQuestionWithAnswersStatus.Verified;
    } else if (questionStatus.every(status => status === FormQuestionWithAnswersStatus.Submitted)) {
      return FormQuestionWithAnswersStatus.Submitted;
    } else if (questionStatus.every(status => status === FormQuestionWithAnswersStatus.Complete || status === FormQuestionWithAnswersStatus.Verified)) {
      return FormQuestionWithAnswersStatus.Complete;
    } else {
      return FormQuestionWithAnswersStatus.Unknown;
    }
  }

  @Selector()
  static verificationRequest(state: CertificationStateModel) {
    return state.verificationRequest;
  }

  @Selector()
  static verificationRequestStatusMessage(state: CertificationStateModel): Message[] {
    if (state.verificationRequest == null) {
      return [];
    }
    switch (state.verificationRequest.status) {
      case VerificationRequestStatus.Submitted:
        return [
          {severity: 'info', summary: 'In Prüfung', detail: 'Deine Dokumente wurden übermittelt und werden nun geprüft.'},
      ];
      case VerificationRequestStatus.Declined:
        return [
          {severity: 'error', summary: 'Abgelehnt', detail: 'Deine Dokumente wurden abgelehnt. Bitte überarbeite deine Dokumente und übermittle sie erneut.'},
        ];
      case VerificationRequestStatus.Verified:
        return [
          {severity: 'success', summary: 'Verifiziert', detail: 'Deine Dokumente wurden erfolgreich verifiziert.'},
        ];
      default:
        return [];
    }
  }

  @Selector()
  static isEditable(state: CertificationStateModel) {
    return state.verificationRequest == null || state.verificationRequest.status === VerificationRequestStatus.Declined;
  }

  @Selector()
  static loading(state: CertificationStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: CertificationStateModel) {
    return state.error;
  }

  @Action(CertificationAction.SetProcessAndInitialize)
  setProcessAndInitialize(ctx: StateContext<CertificationStateModel>, { certificationProcessId }: CertificationAction.SetProcessAndInitialize) {
    ctx.patchState({
      certificationProcessId,
    });
    ctx.dispatch(new CertificationAction.Initialize());
  }

  @Action(CertificationAction.Initialize)
  initialize(ctx: StateContext<CertificationStateModel>) {
    if (ctx.getState().certificationProcess == null) {
      ctx.dispatch(new CertificationAction.LoadProcess());
    }
  }

  @Action(CertificationAction.LoadProcess)
  loadProcess(ctx: StateContext<CertificationStateModel>) {
    ctx.patchState({
      loading: true,
      error: null,
      certificationProcess: null,
    });
    ctx.dispatch(new CertificationApplicationAction.Loading());
    return this.certificationService.getActiveCertificationProcess().pipe(
      tap({
        next: (certificationProcess) => {
          console.log("certificationProcess LOADED", certificationProcess);
          ctx.dispatch(new CertificationAction.SetLoaded(certificationProcess));
        },
        error: (error) => {
          ctx.dispatch(new CertificationAction.Error(getErrorMessageForCode(error)));
        }
      })
    );
  }

  @Action(CertificationAction.SetLoaded)
  setLoaded(ctx: StateContext<CertificationStateModel>, { certificationProcess }: CertificationAction.SetLoaded) {
    ctx.patchState({
      certificationProcess,
      loading: false,
      error: null,
      formResponseAnswers: certificationProcess.formResponse.answers as FormResponseAnswer[],
      documents: certificationProcess.documents ?? [],
      verificationRequest: certificationProcess.verificationRequest ?? null,
    });
    ctx.dispatch(new CertificationApplicationAction.Loaded(certificationProcess.id, certificationProcess.currentCertificationApplication as CertificationApplication | undefined));

    // redirect if at base url

  }

  @Action(CertificationAction.Error)
  error(ctx: StateContext<CertificationStateModel>, { error }: CertificationAction.Error) {
    ctx.dispatch(new CertificationApplicationAction.Error( error ));
    ctx.patchState({
      loading: false,
      error,
    });
  }

  @Action(CertificationAction.AddDocument)
  addDocument(ctx: StateContext<CertificationStateModel>, { document }: CertificationAction.AddDocument) {
    const currentDocuments = [...ctx.getState().documents];
    currentDocuments.push(document);
    ctx.patchState({
      documents: currentDocuments,
    });
  }

  @Action(CertificationAction.RemoveDocument)
  removeDocument(ctx: StateContext<CertificationStateModel>, { documentId }: CertificationAction.RemoveDocument) {
    const currentDocuments = [...ctx.getState().documents];
    const index = currentDocuments.findIndex(document => document.id === documentId);
    const documentToDelete = currentDocuments[index];
    if (index > -1) {
      currentDocuments.splice(index, 1);
      ctx.patchState({
        documents: currentDocuments,
      });
    }
    return this.certificationService.documentDelete(documentId).pipe(
      tap({
        next: (result) => {
          console.log("result", result);
          this.messageService.add({severity:'success', summary:'Gelöscht', detail: 'Das Dokument wurde gelöscht'});
        },
        error: (error) => {
          console.error("Error while deleting document", error);
          this.messageService.add({severity:'error', summary:'Fehler', detail: getErrorMessageForCode(error)});

          const currentDocuments = [...ctx.getState().documents];
          // re-add document at index
          currentDocuments.splice(index, 0, documentToDelete);
          ctx.patchState({
            documents: currentDocuments,
          });
        }
      })
    );
  }

  @Action(CertificationAction.Verification.SelectFormQuestion)
  selectFormQuestion(ctx: StateContext<CertificationStateModel>, { formQuestionId }: CertificationAction.Verification.SelectFormQuestion) {
    ctx.patchState({
      selectedFormQuestionId: formQuestionId,
    });
  }

  @Action(CertificationAction.Verification.CreateOrUpdateAnswerVerification)
  createOrUpdateAnswerVerification(ctx: StateContext<CertificationStateModel>, { payload }: CertificationAction.Verification.CreateOrUpdateAnswerVerification) {

    const { answerId, verificationComment: comment, documentId, documentTypeId } = payload;

    const currentAnswers = [...ctx.getState().formResponseAnswers];
    const answer = currentAnswers.find(answer => answer.id === answerId);
    if (answer == null) {
      return;
    }

    return this.certificationService.answerVerificationCreateOrUpdate({
      formResponseAnswerId: answerId,
      verificationComment: comment ?? '',
      documentId,
      documentTypeId,
    }).pipe(
      tap({
        next: (answerVerification) => {
          const answerVerifications = answer.answerVerifications ? [...answer.answerVerifications] : [];
          answerVerifications.push(answerVerification);
          const updatedAnswer: FormResponseAnswer = {
            ...answer,
            answerVerifications,
          }
          const index = currentAnswers.findIndex(answer => answer.id === answerId);
          currentAnswers[index] = updatedAnswer;

          ctx.patchState({
            formResponseAnswers: currentAnswers,
          });

          const question = ctx.getState().certificationProcess?.formResponse.form.questions.find(question => question!.id === answer.questionId);
          const questionAnswers = currentAnswers.filter(answer => answer.questionId === question?.id);

          const questionStatus = getQuestionStatus(question!, questionAnswers);


          console.log("updated answer", updatedAnswer);
          this.messageService.add({severity:'success', summary:'Gespeichert', detail: 'Die Verifizierung wurde gespeichert'});
        },
        error: (error) => {
          console.error("Error while creating or updating answer verification", error);
          this.messageService.add({severity: 'error', summary: 'Fehler', detail: getErrorMessageForCode(error)});
        }
      })
    );

    /*const updatedAnswerVerification: AnswerVerification = {
      checked: false,
      createdAt: new Date().toISOString(),
      documentId: null,
      id: 'dfsdfs',
      verificationComment: {
        content: comment ?? '',
        createdAt: new Date().toISOString(),
        id: 'sdfsdf',
        answerVerifications: [],
        userId
      },
      verificationRequests: [],
    }*/
  }


  @Action(CertificationAction.Verification.Submit)
  submit(ctx: StateContext<CertificationStateModel>) {
    return this.certificationService.verificationRequestCreate(ctx.getState().certificationProcessId!).pipe(
      tap({
        next: (verificationRequest) => {
          console.log("result", verificationRequest);
          this.messageService.add({severity:'success', summary: 'Übermittelt', detail: 'Ihre Daten wurden erfolgreich übermittelt'});
          ctx.dispatch(new CertificationAction.SetLoaded(verificationRequest.certificationProcess));
        },
        error: (error) => {
          console.error("Error while creating verification request", error);
          this.messageService.add({severity:'error', summary:'Fehler', detail: getErrorMessageForCode(error)});
        }
      }));
  }
}
