import {Injectable, NgZone} from "@angular/core";
import {Action, Selector, State, StateContext, Store} from "@ngxs/store";
import {MessageService} from "primeng/api";
import {tap} from "rxjs";
import {CertificationBaseInfoForm, CertificationBaseInfoStateModel} from "./certificationBaseInfo.model";
import {CERTIFICATION_BASE_INFO_STATE, CHECKOUT_STATE} from "../../../state";
import {CertificationService} from "../../../shared/services/certification.service";
import {CertificationBaseInfoAction} from "./certificationBaseInfo.action";
import {
  CertificationBaseInfo, CertificationProcess,
  CompanyType,
  CreateCertificationBaseInfoInput
} from "../../../shared/graphql/generated/graphql";
import {UpdateFormValue} from "@ngxs/form-plugin";
import {CheckoutStateModel} from "../../../checkout/state/checkout.model";
import {Router} from "@angular/router";
import {CurrentUserState} from "../../../state/current_user/current_user.state";
import {getErrorMessageForCode} from "../../../shared/utils/error_message_mapping";

@State<CertificationBaseInfoStateModel>({
  name: CERTIFICATION_BASE_INFO_STATE,
  defaults: {
    error: null,
    saving: false,
    certificationProcessId: null,
    certificationProcess: null,
    baseInfoForm: {
      dirty: false,
      status: '',
      errors: {},
      model: {
        companyName: '',
        professionId: null,
        email: '',
        website: '',
        address: {
          street: '',
          houseNumber: '',
          zipCode: '',
          city: '',
          state: '',
          country: 'Deutschland'
        },
        representative: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          fax: null,
        },
        openingHours: null,
        operationArea: {
          indoor: 0,
          outdoor: 0,
          heated: 0,
        }
      }
    }
  }
})
@Injectable()
export class CertificationBaseInfoState {

  stripe: any;

  constructor(
    private messageService: MessageService,
    private certificationService: CertificationService,
    private store: Store,
    private router: Router,
  ) {

  }

  @Selector()
  static error(state: CertificationBaseInfoStateModel) {
    return state.error;
  }

  @Selector()
  static saving(state: CertificationBaseInfoStateModel) {
    return state.saving;
  }

  @Selector()
  static baseInfoForm(state: CertificationBaseInfoStateModel) {
    return state.baseInfoForm;
  }

  @Selector()
  static certificationProcess(state: CertificationBaseInfoStateModel) {
    return state.certificationProcess;
  }

  @Action(UpdateFormValue, { cancelUncompleted: true })
  updateFormValue(ctx: StateContext<CheckoutStateModel>, { payload }: UpdateFormValue) {
    console.log('updateFormValue', payload);
  }

  _getCompanyNameFallback(certificationProcess: CertificationProcess): string {
    const fullName =  this.store.selectSnapshot(CurrentUserState.fullName);

    const companyNameCheckout = certificationProcess?.payment?.companyName;

    const companyName = this.store.selectSnapshot(CurrentUserState.companyName);

    const companyType = certificationProcess.companyType;
    const userCompanyName = companyType != CompanyType.SoloSelfEmployed || companyNameCheckout ? (companyNameCheckout || companyName) : fullName;

    return userCompanyName || '';
  }

  @Action(CertificationBaseInfoAction.Set)
  set(ctx: StateContext<CertificationBaseInfoStateModel>, { baseInfo, certificationProcessId, certificationProcess }: CertificationBaseInfoAction.Set) {

    const companyName = this._getCompanyNameFallback(certificationProcess);

    ctx.patchState({
      certificationProcessId,
      certificationProcess
    });
    if (baseInfo) {
      ctx.patchState({
        baseInfoForm: {
          dirty: false,
          status: '',
          errors: {},
          model: this._getFormFromBaseInfo(baseInfo, companyName),
        }
      });
    } else {
      ctx.dispatch(
        new UpdateFormValue({
          path: `${CERTIFICATION_BASE_INFO_STATE}.baseInfoForm`,
          value: companyName,
          propertyPath: 'companyName'
        })
      )
    }
  }

  _getFormFromBaseInfo(baseInfo: CertificationBaseInfo, companyName: string): CertificationBaseInfoForm {
    return {
      professionId: baseInfo.professionId,
      email: baseInfo.email,
      website: baseInfo.website,
      companyName: baseInfo.companyName || companyName,
      address: {
        street: baseInfo.businessAddress.street,
        houseNumber: baseInfo.businessAddress.houseNumber,
        zipCode: baseInfo.businessAddress.zip,
        city: baseInfo.businessAddress.city,
        state: baseInfo.businessAddress.state,
        country: baseInfo.businessAddress.country,
      },
      representative: {
        firstName: baseInfo.representative?.firstName || '',
        lastName: baseInfo.representative?.lastName || '',
        email: baseInfo.representative?.email || '',
        phone: baseInfo.representative?.phone || '',
        fax: baseInfo.representative?.fax || null,
      },
      openingHours: baseInfo.openingHours || null,
      operationArea: {
        indoor: baseInfo.operationalIndoorArea,
        outdoor: baseInfo.operationalOutdoorArea,
        heated: baseInfo.operationalHeatedIndoorArea,
      }
    }
  }

  _getBaseInfoCreateInoutFomForm(form: CertificationBaseInfoForm, certificationProcessId: string): CreateCertificationBaseInfoInput {
    return {
      professionId: form.professionId!,
      companyName: form.companyName,
      email: form.email,
      website: form.website,
      address: {
        street: form.address.street,
        houseNumber: form.address.houseNumber,
        zip: form.address.zipCode,
        city: form.address.city,
        state: form.address.state,
        country: form.address.country,
      },
      certificationProcessId,
      representative: {
        firstName: form.representative.firstName,
        lastName: form.representative.lastName,
        email: form.representative.email,
        phone: form.representative.phone,
        fax: form.representative.fax,
      },
      openingHours: form.openingHours,
      operationalIndoorArea: form.operationArea.indoor,
      operationalOutdoorArea: form.operationArea.outdoor,
      operationalHeatedIndoorArea: form.operationArea.heated,
    }
  }

  @Action(CertificationBaseInfoAction.Save)
  save(ctx: StateContext<CertificationBaseInfoStateModel>) {
    ctx.patchState({
      saving: true,
    });

    const certificationProcess = ctx.getState().certificationProcess;

    if (!certificationProcess) {
      throw new Error('certificationProcess is not set');
    }

    const companyNameFallback = this._getCompanyNameFallback(certificationProcess);

    return this.certificationService.createOrUpdateBaseInfo(this._getBaseInfoCreateInoutFomForm(ctx.getState().baseInfoForm.model, ctx.getState().certificationProcessId!)).pipe(
    ).pipe(
      tap({
        next: (certificationBaseInfo) => {

          ctx.patchState({
            saving: false,
            error: null,
            baseInfoForm: {
              ...ctx.getState().baseInfoForm,
              model: this._getFormFromBaseInfo(certificationBaseInfo, companyNameFallback),
            }
          });

          this.messageService.add({severity:'success', summary:'Gespeichert', detail:'Informationen erfolgreich gespeichert'});
          this.router.navigate(['/certification', 'verification']);
        },
        error: (error) => {
          ctx.dispatch(new CertificationBaseInfoAction.Error(getErrorMessageForCode(error)));
        }
      })
    );
  }

  @Action(CertificationBaseInfoAction.Error)
  error(ctx: StateContext<CertificationBaseInfoStateModel>, { error }: CertificationBaseInfoAction.Error) {
    ctx.patchState({
      saving: false,
      error,
    });
  }
}
