import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { AuthState } from "../../state/auth/auth.state";
import { Auth } from "../../state/auth/auth.action";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(private store: Store, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (this.router.url !== '/auth/login') {
    await lastValueFrom(this.store.dispatch(new Auth.Initialize()));
    // }
    if (this.store.selectSnapshot<boolean>(AuthState.isLoggedIn)) {
      console.log("User is authenticated");
      return true;
    }
    console.log("User is not authenticated", state.url);
    this.store.dispatch(new Auth.NavigationFailed(state.url))
    this.router.navigate(['/login']);
    return false;
  }

  async canActivateChild() {
    /*if (await this.authService.isAuthenticated()) {
      console.log("User is authenticated");
      return true;
    }
    console.log("User is not authenticated");
    this.route.navigate(['/login']);
    return false;*/

    return true;
  }
}
