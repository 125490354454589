import { Component, ElementRef } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import {Store} from "@ngxs/store";
import {Auth} from "../state/auth/auth.action";
import {CheckoutAction} from "../checkout/state/checkout.action";

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html'
})
export class AppSidebarComponent {

    constructor(public layoutService: LayoutService, public el: ElementRef, private readonly store: Store) { }

  logout() {
      this.store.dispatch(new Auth.Logout());
      this.store.dispatch(new CheckoutAction.Clear());
  }
}
